.addPhone {
    min-height: 100%;
    .verify-phone-title {
        height: 60px;
        line-height: 30px;
        font-size: 20px;
        margin-bottom: 0;
        padding: 15px 0;
        text-align: center;
    }
}