.agreement {
    padding: 20px 20px 100px;
    .btn-flex {
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        background-color: white;
        padding: 20px;
        box-shadow: 2px 2px 20px #CCCCCC;
    }
}