.demo {
  width: 100%;
  height: 100%;
  background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/home/bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  .header, .content { width: 320px; }
  .header {
    display: flex;
    height: 55px;
    padding-top: 30px;
    margin: 0 auto 20px;
    .home {
      width: 25px;
      height: 25px;
      margin-right: 30px;
      background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/header/home.png");
      background-repeat: no-repeat;
      background-size: 100%;
      transition: all 500ms ease;
    }
    .title {
      flex: 1;
      height: 25px;
      line-height: 25px;
      color: white;
      font-size: 23px;
      font-family: "Songti SC";
      font-weight: bolder;
      text-align: right;
    }
    .home:active { transform: scale(.65); }
  }
  .content {
    margin: 0 auto;
    border: 2px solid white;
    height: calc(100% - 105px);
    position: relative;

    .container-1, .container-2 {
      width: 100%;
      height: 100%;
      overflow: hidden;
      .top, .bottom {
        width: 280px;
        padding: 8px;
        margin: 0 auto;
        border: 2px solid white;
        color: white;
        font-size: 15px;
        border-radius: 10px;
      }
      .top {
        margin-top: 30px;
      }
      .center {
        width: 90px;
        height: 150px;
        margin: 20px auto;
        position: relative;
      }
    }
  }
  .container {
    width: 320px;
    height: calc(100% - 100px);
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    border: 2px solid white;
    .logo {
      width: 155px;
      height: 100px;
      margin: 40px auto;
      background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/home/logo.png");
      background-repeat: no-repeat;
      background-size: 100%;
    }

    .slogan {
      width: 100%;
      height: 50px;
      color: #DDDDDD;
      font-size: 25px;
      line-height: 50px;
      margin: 0;
      text-align: center;
      font-family: "Songti SC";
      font-weight: bold;
    }
    .qrCode {
      width: 170px;
      height: 170px;
      margin: 15px auto;
      border: 2px solid white;
      box-sizing: content-box;
      img {
        margin: 10px;
        width: 150px;
        height: 150px;
      }
    }
    .companyName {
      height: 35px;
      margin: 0;
      text-align: center;
      line-height: 35px;
      color: white;
      font-weight: bolder;
      font-size: 16px;
    }

  }
  .toNext, .toTop {
    position: absolute;
    bottom: 0;
    width: 50px;
    height: 50px;
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: 50%;
    transition: all 500ms ease;
  }
  .toTop {
    background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/header/toTop.png");
    left: 0;
  }
  .toNext {
    background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/header/toNext.png");
    right: 0;
  }
  .toNext:active, .toTop:active {
    transform: scale(.65);
  }
}