.coupon_enclose-bg {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 101;
	background-color: rgba(150, 150, 150, .3);
}
.coupon_enclose-bg>.coupon_enclose{
	background-color: rgba(255, 255, 255, .8);
	backdrop-filter: blur(20px);
	position: absolute;
	top: 50%;
	transform: translate(-50%,-50%);
	left: 50%;
	width: 78%;
	border-radius: 4%;
	z-index:102;
	overflow:hidden;
	padding: 10px 15px;
	border: 1px solid #AAAAAA;
}
.coupon_enclose>.title {
	text-align: center;
}
.coupon_enclose>.buttonGroup {
	margin-top: 20px;
}
.coupon_enclose>.buttonGroup>.left {
	margin-right: 20px;
}
.coupon_enclose>.captcha {
	margin: 15px 0;

}

