.design {
  width: 100%;
  height: 100%;
  background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/home/bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .header, .content { width: 320px; }
  .header {
    display: flex;
    height: 55px;
    padding-top: 30px;
    margin: 0 auto 20px;
    .home {
      width: 25px;
      height: 25px;
      margin-right: 30px;
      background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/header/home.png");
      background-repeat: no-repeat;
      background-size: 100%;
      transition: all 500ms ease;
    }
    .title {
      flex: 1;
      height: 25px;
      line-height: 25px;
      color: white;
      font-size: 23px;
      font-family: "Songti SC";
      font-weight: bolder;
      text-align: right;
    }
    .home:active { transform: scale(.65); }
  }
  .content {
    margin: 0 auto;
    border: 2px solid white;
    height: calc(100% - 105px);
    position: relative;
    .container-1 {
      .info_list {
        width: 260px;
        margin: 50px auto 25px;
        li {
          display: flex;
          height: 30px;
          color: white;
          margin-bottom: 15px;
          .index {
            width: 30px;
            height: 30px;
            margin-right: 15px;
            line-height: 26px;
            text-align: center;
            border-radius: 50%;
            border: 2px solid white;
            font-size: 20px;
            font-weight: bolder;
          }
          .detail {
            height: 30px;
            line-height: 30px;
            color: white;
            font-weight: bolder;
            font-size: 16px;
          }
        }
      }
      .info-img {
        width: 260px;
        height: 165px;
        margin: 0 auto;
        background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/header/table.png");
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }
    .container-2 {
      .info_list {
        width: 280px;
        margin: 10px auto 0;
        li {
          line-height: 25px;
          font-size: 15px;
          color: white;
          margin-bottom: 10px;
        }
      }
      .info-images {
        display: flex;
        width: 280px;
        margin: 0 auto;
        &>div {
          flex: 1;
          img {
            width: 100%;
            height: 100%;
          }
        }
        div:nth-child(2) {
          margin: 0 5px;
        }
      }

    }
    .container-3 {
      color: white;
      .detail {
        width: 280px;
        margin: 30px auto 20px;
        line-height: 25px;
        font-size: 17px;
      }
      .videoContainer {
        width: 150px;
        height: 200px;
        margin: 0 auto 15px;
      }
      .info {
        color: white;
        width: 280px;
        margin: 0 auto;
        font-size: 15px;
      }
    }
    .container-4 {
      p {
        width: 280px;
        margin: 50px auto 60px;
        color: white;
        line-height: 30px;
        font-size: 16px;
      }
      img { width: 100%; }
    }
    .toNext, .toTop {
      position: absolute;
      bottom: 0;
      width: 50px;
      height: 50px;
      background-size: 50%;
      background-repeat: no-repeat;
      background-position: 50%;
      transition: all 500ms ease;
    }
    .toTop {
      background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/header/toTop.png");
      left: 0;
    }
    .toNext {
      background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/header/toNext.png");
      right: 0;
    }
    .toNext:active, .toTop:active {
      transform: scale(.65);
    }
  }
}