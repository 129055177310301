.micro-player {
    background-color: black;
    .marquee {
        position: absolute;
        top: 30px;
        height: 30px;
        z-index: 16;
        opacity: 0;
        color: red;
        animation: marqueeAn 3s linear infinite;
    }
    @keyframes marqueeAn {
        0% {
            opacity: 0;
        }
        20%{
            opacity: 1;
        }
        40%{
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }
    .video-container {
        display: flex;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 12;
        video {
            object-fit: contain;
            background-color: #000000;
        }
    }
    .video-wall {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0);
        z-index: 13;
        .video-first-play, .video-first-loading, .video-wrapper-ctrl {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 14;
        }

        .video-first-play {
            background-color: rgba(0, 0, 0, .3);
            .info {
                position: absolute;
                width: 100%;
                height: 80px;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                .to-play {
                    width: 24px;
                    height: 24px;
                    margin: 0 auto 10px;
                    padding: 10px;
                }
                .name {
                    height: 26px;
                    text-align: center;
                    line-height: 26px;
                    color: white;
                    font-size: 20px;
                }
            }
        }
        .video-first-loading {
            background-color: rgba(0, 0, 0, .3);
            &>div {
                margin: 0;
                height: 30px;
                line-height: 30px;
                text-align: center;
                user-select: none;
                position: absolute;
                width: 100%;
                top: 50%;
                transform: translateY(-50%);
                color: #fff;
                font-size: 14px;
            }
        }
        .video-wrapper-ctrl {
            overflow: hidden;
            .play-loading, .video-end {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, .3);
                .info {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 100%;
                    text-align: center;
                    transform: translateY(-50%);
                    font-size: 16px;
                    color: #fff;
                }
            }
            .video-ctrl-bar {
                position: absolute;
                width: 100%;
                height: 44px;
                left: 0;
                bottom: 0;
                background-image: linear-gradient(0deg, rgba(0, 0, 0, .76), transparent);
                display: flex;
                transition: visibility .4s,opacity .4s,transform .4s,-webkit-transform .4s;
                .ctrl-play, .ctrl-pause, .to-no-full-btn, .to-full-btn {
                    width: 44px;
                    height: 44px;
                    padding: 10px;
                    line-height: 24px;
                    font-size: 20px;
                    background-color: transparent;
                    -webkit-tap-highlight-color: rgba(0,0,0,0);
                }
                .video-progress {
                    flex: 1;
                    display: flex;
                    height: 44px;
                    position: relative;
                    .video-currentTime, .video-duration {
                        line-height: 44px;
                        color: white;
                        font-size: 14px;
                        user-select: none;
                    }
                    .progress-bar {
                        flex: 1;
                        margin: 20px 10px;
                        overflow: hidden;
                        height: 4px;
                        background-color: #CCCCCC;
                        border-radius: 5px;
                        position: relative;
                        .progress-bar-inner {
                            position: absolute;
                            left: 0;
                            top: 0;
                            height: 100%;
                            background-color: #1890ff;
                            border-radius: 5px;
                            transition: width .4s ease;
                        }
                    }
                }
            }
            .video-ctrl-bar-show {
                visibility: visible;
                opacity: 1;
                transform: translateY(0);
            }
            .video-ctrl-bar-hidden {
                visibility: hidden;
                opacity: 0;
                transform: translateY(10px);
            }
        }
        i {
            display: inline-block;
            width: 24px;
            height: 24px;
            text-align: center;
            line-height: 24px;
            font-weight: 400;
            font-style: normal;
            fill: white;
            transition: fill .2s;
            user-select: none;
        }
        i:active {
            fill: #AAAAAA;
        }
    }
}

.micro-player-horizontal {
    position: fixed;
    z-index:11;
    top:0;
    left:0;
    .video-container {
        video {
            margin: 0 auto;
        }
    }
}

.micro-player-full {
    position: fixed;
    z-index:11;
    top:0;
    left:0;
    .video-container {
        top: 50%;
        transform: translateY(-50%);
        video {
            margin: 0 auto;
        }
    }
}

.micro-player-no-full {
    position: relative;
    .video-container {
        video {
            margin: 0 auto;
        }
    }
}