body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, #root, .app{
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}
ul, li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}