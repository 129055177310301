.buy {
  width: 100%;
  height: 100%;
  .pay-success {
    width: 100%;
    height: 100%;
    padding: 50px 0 0;
    background-color: white;
  }
  .loading {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    backdrop-filter: blur(20px);
    .loading-content {
      padding: 15px;
      background-color: rgba(70, 70, 70, .5);
      border-radius: 5px;
      backdrop-filter: blur(20px);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .course-header {
    padding: 8px 10px;
    display: flex;
    background-color: white;
    margin-bottom: 15px;
    .header-name {
      flex: 1;
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      color: #000000;
      .header-link {
        display: inline-block;
        transition: color 300ms ease;
      }
      .header-link:active {
        color: #3385ff;
      }
    }
  }
  .buy-title {
    height: 24px;
    line-height: 24px;
    margin-bottom: 10px;
    padding-left: 14px;
  }
  .courses {
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    margin: 0 10px 15px;
    .course-item {
      display: flex;
      border-bottom: 1px solid #EEEEEE;
      padding-bottom: 10px;
      margin-bottom: 10px;
      .courseIndex {
        width: 106px;
        height: 60px;
        img {
          width: 106px;
          height: 60px;
        }
      }
      .info {
        flex: 1;
        padding: 0 10px;
        .lessonName {
          margin-bottom: 10px;
        }
        .lessonName {
          @media screen and (max-width: 375px) {
            max-width: 150px;
          }
          @media screen and (max-width: 320px) {
            max-width: 100px;
          }
          height: 25px;
          line-height: 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: bold;
          color: #000000;
          font-size: 16px;
        }
      }
      .coursePrice {
        color: #FF4400;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .total {
      text-align: right;
      font-size: 18px;
      color: #000000;
      font-weight: bold;
      height: 25px;
      line-height: 25px;
    }
  }
  .pay-method {
    padding: 10px;
    margin: 10px;
    background-color: white;
    border-radius: 5px;
    .wechat-pay {
      border-bottom: 1px solid #FAFAFA;
      padding-bottom: 5px;
    }
    .alipay {
      padding-top: 5px;
    }
    &>div {
      height: 35px;
      display: flex;
      overflow: hidden;
      i {
        display: inline-block;
        width: 35px;
        height: 35px;
        text-align: center;
        line-height: 40px;
      }
      .pay-name {
        line-height: 35px;
        color: #000000;
        font-weight: bold;
        font-size: 16px;
        flex: 1;
      }
      .check {
        width: 15px;
        height: 15px;
        margin: 10px;
        transition: background-color 300ms ease;
        border: 1px solid #EEEEEE;
        border-radius: 50%;
      }
      .no-check {
        background-color: #DDDDDD;
      }
      .checked {
        background-color: #3385ff;
      }
    }
  }
  .pay {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    display: flex;
    padding: 20px 10px;
    background-color: white;
    .trust-amount {
      flex: 1;
      font-size: 18px;
      font-weight: bold;
      height: 40px;
      line-height: 40px;
    }
    .toPay {
      height: 40px;
      line-height: 40px;
      text-align: center;
      padding: 0 35px;
      border-radius: 30px;
      background-color: #1890ff;
      color: white;
      font-size: 18px;
      transition: background-color 300ms ease;
      user-select: none;
    }
    .toPay:active {
      background-color: #096dd9;
    }
  }
}