.mycourse_head{
    position: relative;
    width: 120%;
    height: 20%;
    left: -10%;
    background-repeat:no-repeat;
    background-position: 54% 53%;
    padding-top: 10px;
    position: relative;
    border-radius: 0 0 70px 70px;
    /*transform: rotate(15deg);*/
}
.mycourse_backlog{
    position: absolute;
    top: 5%;
    background-color: white;
    width: 86%;
    height: 23%;
    z-index: 999;
    left: 50%;
    transform: translate(-50%);
    border-radius: 10px;
    box-shadow: 0px 2px 2px 0 #ccc;
}
.mycourse_total{
    width: 100%;
    height: 100%;
}
.mycourse_backlog_des{
    position: absolute;
    top: 8%;
    background-color: red;
    width: 86%;
    height: 17%;
    z-index: 999;
    left: 50%;
    transform: translate(-50%);
    border-radius: 10px;
}
.mycourse_total .active{
    background-color:white;
    border-radius:20px;
}

.active{
    background-color: white;
    border-radius: 20px;
    width: 60%;
    height: 40px;
    display: inline-block;
     color: #3974F6;
    font-size: 16px;
    text-align: center;
    line-height: 40px;
}
.unactive{
    background-color:#3974F6;
     border-radius: 20px;
    width: 40%;
    height: 40px;
    display: inline-block;
    color: white;
    font-size: 16px;
    text-align: center;
    line-height: 40px;
}