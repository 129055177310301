.content{
	position:absolute;
	h2{
	margin-top:15px;
	text-align:center;
	width:100%;
	}

	p{
	width:90%;
	color:black;
	margin-left:5%;
	}

	.button1{
	margin-top:50px;
	width:60%;
	height:40px;
	margin-left:20%;
	border-radius:20px;
	}
	.button2{
	margin-top:15px;
	width:60%;
	height:40px;
	margin-left:20%;
	border-radius:20px;
	}
}