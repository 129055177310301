@import '~antd/dist/antd.css';

@font-face {font-family: "iconfont";
  src: url('./fonts/iconfont.eot?t=1580534766858'); /* IE9 */
  src: url('./fonts/iconfont.eot?t=1580534766858#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('data:application/x-font-woff2;charset=utf-8;base64,d09GMgABAAAAAAN8AAsAAAAAB7AAAAMvAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHEIGVgCDMgqCSIIhATYCJAMUCwwABCAFhG0HThuzBsgGHv5/M7vPUkrqQs3GvyruTAIZJlpCKmorYv65JweX45YvkHlty+0b28YePjZgFHBAY20qogIpIG4Yu6Al7ocAbHowFDFx8syFWGjMYQIQmSDlYmVcaDSLWghiFXM14iQGSx6SrwIngs+LX6gRFkiMwjxorj8pydjvWvz0jNpZ3slKdSVXTHcYgAEUMBTQIMJKUwYVhYaisNXfacYCNhYS+V2L7+u+n/3TM+UyaCLTIrAtE8jD/PEUiBgosxkAY6FcX5zQ4UHC93U8CPh+Ng8afnomjAKeywJ2FFkKhBjX1ygR1pqtWnVo1mHDkcfQOx/ta1//eBdzyRPxZllSZ30hXphdiStSNzzUnYTLpi+9t389m/sX79qSfPf9xeaDDxZ57+3cctPOgclr7+8h1gI2c8Uj/Vyxy7efdhns9HUGn+x5cnDLvi0HdznaJUAQUtW2C8tveGNWdnfHk+ZR7+xOJ5od22YeOdX88LY3Zv41lLorn/+k897pdwfykhp56d0N8Us+6bRr7ruMWHezqBDfrDh+Sc8LWj1z+eUtbulyzfZWz57vfE+OjZgdVR/5OZT/V/crE1Bc9Zq6MvKbelG1/z/f4a9n38mtaTnyPyuuAfh4xdDfobPsaroLXrISVfg3hB2rtCY21UYruY5AsRicCRRSbbKBf2Brw+fTqNp3TgOBxTYzQdKE7gkoLPqiNG4oGOLUQQyLMWAzhCmL47QlRCF0U2Aw+wAIWnEHSFrwBCha8RpK4z4DQwd+gxithAKbxaLtmnH6i8CeEHpu5CWd+k1OkMjnfFsb2WH2fC9VyrhhWhyQF3thMfA61RVV+egUL+eFU0work/VRJHv+GE+60wGh3mZTN4phPlGLxFVNERRYXhlpV/1pIpEPovNBEI8XCI8kjjUswmHgAR5cvhe4wi78P75eKQokcElbMir8i/GI6RI0LpDNRVUdUBNUeQ65d3KI0XWk6KGiAgfB5+QPFkcJoNeHhky5HEoVPdqxCNBRAUNAwIFhlNp1fO7KiqWV2VfIMKKMK81QgoltDAihl1wiyVvWCHjbool3fzCUqCTLk3SJTdoDHIAAA==') format('woff2'),
  url('./fonts/iconfont.woff?t=1580534766858') format('woff'),
  url('./fonts/iconfont.ttf?t=1580534766858') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
  url('./fonts/iconfont.svg?t=1580534766858#iconfont') format('svg'); /* iOS 4.1- */
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-pasue-play:before {
  content: "\e634";
}

.icon-daoTui:before {
  content: "\e50e";
}

.icon-play:before {
  content: "\e9c3";
}

.icon-kuaijin:before {
  content: "\e64e";
}

.App {
  width: 100%;
  height: 100%;
}

.app{
  height: 100%;
}
.app>div {
  /*min-height: 100%;*/
  width: 100%;
  overflow: hidden;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  width: 100%;
  height: 100%;
}

.app>.selected-type {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(255, 255, 255, .8);
  backdrop-filter: blur(20px);
}
.app>.selected-type>.content {
  position: absolute;
  width: 100%;
  top: 20%;
  padding: 0 20px;
}
.app>.selected-type>.content>.selected-type-title {
  text-align: center;
  height: 25px;
  margin-bottom: 20px;
  font-weight: bold;
  line-height: 25px;
  color: #000000;
  font-size: 1.5rem;
}
.app>.selected-type>.content>.des {
  margin-bottom: 20px;
  font-size: 1rem;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}