.integral {
  padding-bottom: 100px;
  width: 100%;
  .integral-header {
    padding: 8px 10px;
    display: flex;
    background-color: white;
    margin-bottom: 15px;
    font-size:12px;
    .header-name {
      flex: 1;
      height: 30px;
      line-height: 30px;
      color: #000000;
      .header-link {
        display: inline-block;
        transition: color 300ms ease;
      }
      .header-link:active {
        color: #3385ff;
      }
    }
  }
  .myWallet{
  width:94%;
  height:80px;
  margin-left:3%;
  background:#2f54eb;
  border-radius:10px;
  color:white;
  padding:30px 20px;
  box-shadow:0px 1px 2px -2px rgba(0,0,0,0.22),
             0px 3px 6px 0px rgba(0,0,0,0.16),
             0px 5px 12px 4px rgba(0,0,0,0.12);
  }
  .integral-content{
        width:100%;
        display:flex;
        margin-top:15px;
        justify-content:center;

  .conversion{
  width:100px;
  height:40px;
  border:0px;
  background:#d4380d;
  border-radius:10px;
  color:white;
  padding:10px 0;
  margin:10px;
  }
  .detail{
  width:100px;
  height:40px;
  border:0px;
  background:#13c2c2;
  color:white;
  padding:10px 0;
  border-radius:10px;
  margin:10px;
  }
  }
  }