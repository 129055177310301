.head{
    width: 120%;
    height: 29%;
    background-repeat:no-repeat;
    background-position: 54% 53%;
    
    padding: 10%;
    padding-top: 10px;
    left: -10%;
    position: relative;
    border-radius: 0 0 70px 70px;
}
.per_title{
    width: 100%;
    height: 6%;
    position: relative;
}
.title_son{
    position:absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.slogan{
    color: white;
    font-size: 25px;
    width: 100%;
    display: inline-block;
    position: relative;
    height: 41px;
}
.left{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    vertical-align:middle;
    margin-left: 15px;
}
.right{
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 10px;
    border-radius: 20px;
    border-style:solid;
    border-color: white;
    border-width: 1px;
    font-size: 13px;
    right: 10px;
    padding: 3px 7px;
}
.Welcome{
    color: white;
    padding: 10px 20px 0px;
}
.backlog{
    position: absolute;
    top: 27%;
    background-color: white;
    width: 86%;
    height: 27%;
    z-index: 999;
    left: 50%;
    transform: translate(-50%);
    border-radius: 10px;
}
.check{
    display: inline-block;
    position: absolute;
    top: 55%;
    width: 100%;
    height: 100px
}
.bottom{
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 50%;
  width: 80%;
  height: 40px;
  background: #1890ff;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  color: white;
}
