

@media screen and (max-height: 668px) {
    .sunbac{
        font-size:calc(100vw / 96);
        width: 100%;
        height:100%;
        background:url(../photo/bac.png);
        background-size:100% 100%;
        background-repeat:no-repeat;
    }
    .sunbtnone{

        position: absolute;
        left: 10%;
        top: 18rem;
        width: 38%;
        height: 6.5%;
        background: url(../photo/btn.png);
        background-size: 100% 100%;
        background-repeat:no-repeat;
        color: white;
        font-weight: 600;
        line-height: 190%;
        font-size: 500%;
        letter-spacing: 0.1rem;
        text-align: center;

    }
    .sunbtntwo{
        position: absolute;
        right: 10%;
        top: 18rem;
        width: 38%;
        height: 6.5%;
        color: white;
        font-weight: 600;
        line-height: 190%;
        font-size: 500%;
        letter-spacing: 0.1rem;
        background: url(../photo/btn.png);
        background-size: 100% 100%;
        background-repeat:no-repeat;
        text-align: center;
    }
    .sunbtnthree{
        position: absolute;
        left: 10%;
        top: 21.5rem;
        width: 38%;
        height: 6.5%;
        color: white;
        font-weight: 600;
        line-height: 190%;
        font-size: 500%;
        letter-spacing: 0.1rem;
        background: url(../photo/btn.png);
        background-size: 100% 100%;
        background-repeat:no-repeat;
        text-align: center;
    }
    .sunbtnfour{
        position: absolute;
        right: 10%;
        top: 21.5rem;
        width: 38%;
        height: 6.5%;
        color: white;
        font-weight: 600;
        line-height: 190%;
        font-size: 500%;
        letter-spacing: 0.1rem;
        background: url(../photo/btn.png);
        background-size: 100% 100%;
        background-repeat:no-repeat;
        text-align: center;
    }
    .suntitle{
        position: relative;
        top: 1.1rem;
        width: 19rem;
        height: 4rem;
        text-align: center;
        margin: 0 auto;
        background: url(../photo/title.png);
        background-size: cover;
    }
    .sunbanner{
        position: relative;
        top: 1rem;
        width: 5.8rem;
        height: 5rem;
        text-align: center;
        margin: 0 auto;
        background:url(../photo/logo.png);
        background-size:cover;
    }
    .bottoms{
        position: absolute;
        bottom: 0%;
        width: 100%;
        height: 12rem;
        background:url(../photo/btn2.png);
        background-size:100% 100%;
        background-repeat: no-repeat;
    }
}
@media screen and (min-height: 669px) {
    .sunbac{
        font-size:calc(100vw / 96);
        width: 100%;
        height:100%;
        background:url(../photo/bac.png);
        background-size:cover;
        background-repeat:no-repeat;
    }
    .sunbtnone{

        position: absolute;
        left: 10%;
        top: 23rem;
        width: 38%;
        height: 6.5%;
        background: url(../photo/btn.png);
        background-size: contain;
        background-repeat:no-repeat;
        color: white;
        font-weight: 600;
        line-height: 200%;
        font-size: 500%;
        letter-spacing: 0.1rem;
        text-align: center;

    }
    .sunbtntwo{
        position: absolute;
        right: 10%;
        top: 23rem;
        width: 38%;
        height: 6.5%;
        color: white;
        font-weight: 600;
        line-height: 200%;
        font-size: 500%;
        letter-spacing: 0.1rem;
        background: url(../photo/btn.png);
        background-size: contain;
        background-repeat:no-repeat;
        text-align: center;
    }
    .sunbtnthree{
        position: absolute;
        left: 10%;
        top: 28rem;
        width: 38%;
        height: 6.5%;
        color: white;
        font-weight: 600;
        line-height: 200%;
        font-size: 500%;
        letter-spacing: 0.1rem;
        background: url(../photo/btn.png);
        background-size: contain;
        background-repeat:no-repeat;
        text-align: center;
    }
    .sunbtnfour{
        position: absolute;
        right: 10%;
        top: 28rem;
        width: 38%;
        height: 6.5%;
        color: white;
        font-weight: 600;
        line-height: 200%;
        font-size: 500%;
        letter-spacing: 0.1rem;
        background: url(../photo/btn.png);
        background-size: contain;
        background-repeat:no-repeat;
        text-align: center;
    }
    .title{
        position: relative;
        top: 3rem;
        width: 20rem;
        height: 3.5rem;
        text-align: center;
        margin: 0 auto;
        background: url(../photo/title.png);
        background-size: cover;
    }
    .sunbanner{
        position: relative;
        top: 1rem;
        width: 4.5rem;
        height: 3.9rem;
        text-align: center;
        margin: 0 auto;
        background:url(../photo/logo.png);
        background-size:cover;
    }
    .sunbottoms{
        position: absolute;
        bottom: 0%;
        width: 100%;
        height: 14rem;
        background:url(../photo/btn2.png);
        background-size:cover;
    }
}
