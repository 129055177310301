.preloadVideo {
  width: 100%;
  height: 100%;
  position: relative;
  .headerImage {
    width: 100%;
    height: 100%;
  }
  .open_btn {
    width:20%;
    position: absolute;
    top: 50.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    border-radius: 50%;
    transition: all 400ms ease;
    position: absolute;
  }
  .anticon{
    height:100%
  }
  .anticon svg{
    width:100%;
    height:100%;
  }
  .open_btn:active {
    color: #AAAAAA;
    transform: translate(-50%, -50%) scale(.8);
  }
  .video_content {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    .video {
      width: 80%;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .close {
      color: white;
      transition: all 400ms ease;
      font-size: 20px;
    }
    .close:active {
      color: #AAAAAA;
    }
  }
}