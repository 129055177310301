.personal {
  .logout {
    padding: 0 10px 0;
    button {
      width: 100%;
    }
  }
  .header-name {
      margin-left:15px;
      flex: 1;
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      color: #000000;
      .header-link {
        display: inline-block;
        transition: color 300ms ease;
      }
      .header-link:active {
        color: #3385ff;
      }
    }
}