.gameList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  list-style: none;
  padding: 10px;
  .game-item {
    width: calc(50% - 20px);
    padding: 10px;
    border-radius: 5px;
    margin: 10px;
    background-color: white;
    transition: background-color 400ms ease;
    .game-index {
      height: 79px;
      border: 0px solid #CCCCCC;
      margin-bottom: 5px;
      img {
        width: 100%;
      }
    }
    .gameName {
      margin-bottom: 0;
      color: #000000;
    }
  }
  .game-item:active {
    background-color: whitesmoke;
  }
}