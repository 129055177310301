.bactwo {
    width: 100%;
    height: 100%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/newmebac.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.sunthreeneirongs{
    position: absolute;
    left: 10%;
    top: 10%;
    width: 80%;
    height: 75%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.newmethreejuxing{
    position: absolute;
    z-index: 1000;
    top: 0;
    width: 100%;
    font-size: 125%;
    font-weight: 600;
    height: 100%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/newmebac.png');
    background-size: 100% 100%;

}
.newmethreetui{
    position: absolute;
    top: 78%;
    left: 30%;
    width: 40%;
    font-size: 125%;
    font-weight: 600;
    z-index: 1001;
    height: 8%;
    background-size: 100% 100%;
}
.videoContainer {
    position: absolute;
    width: 61%;
    height: 38%;
    top: 28%;
    left: 20%;
    background-color: azure;
    background: url(../photo/video.png);
    background-size: 100% 100%;
}
.preloadVideo {
    width: 100%;
    height: 100%;
    position: relative;
}

.newmeonebactui {
    position: absolute;
    top: 17.5%;
    left: 10%;
    width: 3.5rem;
    height: 3.5rem;
    font-size: 125%;
    font-weight: 600;
    z-index: 100;
    background: url(../photo/tuichu.png);
    background-size: 100% 100%;
}
.bacthree {
    width: 100%;
    height: 100%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/newmebac.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.sunthreetop {
    position: relative;
    top: 3.5rem;
    width: 9rem;
    height: 3.8rem;
    text-align: center;
    margin: 0 auto;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/photo/peitaotitle.png');
    background-size: cover;
}
.sunthreebtnone {
    position: absolute;
    left: 8%;
    top: 27%;
    padding-bottom: 9%;
    width: 38%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/photo/wenti.png');
    background-size: contain;
    background-repeat: no-repeat;
}
.sunthreebtntwo {
    position: absolute;
    right: 8%;
    top: 27%;
    padding-bottom: 9%;
    width: 38%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/photo/jiankang.png');
    background-size: contain;
    background-repeat: no-repeat;
}
.sunthreebtnthree {
    position: absolute;
    left: 8%;
    top: 41%;
    padding-bottom: 9%;
    width: 38%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/photo/zhaopin.png');
    background-size: contain;
    background-repeat: no-repeat;
}
.sunthreebtnfour {
    position: absolute;
    right: 8%;
    top: 41%;
    padding-bottom: 9%;
    width: 38%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/photo/peixun.png');
    background-size: contain;
    background-repeat: no-repeat;
}
#bactwo video{
    width:90%;
    height: 90%;
}
.sunthreebtnfive {
    position: absolute;
    left: 8%;
    top: 57%;
    padding-bottom: 9%;
    width: 38%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/photo/jinsheng.png');
    background-size: contain;
    background-repeat: no-repeat;
}
.sunthreebtnsix {
    position: absolute;
    right: 8%;
    top: 57%;
    padding-bottom: 9%;
    width: 38%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/photo/duihua.png');
    background-size: contain;
    background-repeat: no-repeat;
}
.sunthreebtnones {
    position: absolute;
    left: 8%;
    top: 26%;
    padding-bottom: 9%;
    width: 38%;
    background: url(../photo/wentis.png);
    background-size: contain;
    background-repeat: no-repeat;
}
.sunthreebtntwos {
    position: absolute;
    right: 8%;
    top: 26%;
    padding-bottom: 9%;
    width: 38%;
    background: url(../photo/jiankangs.png);
    background-size: contain;
    background-repeat: no-repeat;
}
.sunthreebtnthrees {
    position: absolute;
    left: 8%;
    top: 40%;
    padding-bottom: 9%;
    width: 38%;
    background: url(../photo/zhaopins.png);
    background-size: contain;
    background-repeat: no-repeat;
}
.sunthreebtnfours {
    position: absolute;
    right: 8%;
    top: 40%;
    padding-bottom: 9%;
    width: 38%;
    background: url(../photo/peixuns.png);
    background-size: contain;
    background-repeat: no-repeat;
}
.sunthreebtnfives {
    position: absolute;
    left: 8%;
    top: 56%;
    padding-bottom: 9%;
    width: 38%;
    background: url(../photo/jinshengs.png);
    background-size: contain;
    background-repeat: no-repeat;
}
.sunthreebtnsixs {
    position: absolute;
    right: 8%;
    top: 56.5%;
    padding-bottom: 9%;
    width: 38%;
    background: url(../photo/duihuas.png);
    background-size: contain;
    background-repeat: no-repeat;
}
.newmeonebactui {
    position: absolute;
    top: 81.5;
    left: 80%;
    width: 3rem;
    height: 3rem;
    font-size: 125%;
    font-weight: 600;
    z-index: 100;
    background: url('https://micro-weixun.oss-cn-beijing.aliyuncs.com/static/back4.png');
    background-size: 100% 100%;
}
.newmefourpone {
    position: relative;
    top: 14%;
    width: 90%;
    padding-bottom: 35%;
    left: 5%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/photo/newmefourp.png');
    background-size: 100% 100%;
 }
.newmefourbactui {
    position: absolute;
    top: 87%;
    left: 44%;
    width: 12%;
    padding-bottom: 12%;
    font-size: 125%;
    font-weight: 600;
    background: url(../photo/tuichutwo.png);
    background-size: 100% 100%;
}
.newmefouronepic {
    position: relative;
    width: 12%;
    top: 40%;
    left: 15%;
}
.newmefouronep1 {
    position: relative;
    width: 50%;
    margin-top: -14%;
    color: white;
    font-size: 110%;
    left: 34%;
}
.newmefouronep2{
    position: relative;
    width: 59%;
    margin-top: 0;
    color: white;
    font-size: 110%;
    left: 34%;
}
.newmefourtwopic{
    position: relative;
    width: 12%;
    top: 57%;
    left: 15%;
}
.newmefourtwop1 {
    position: relative;
    width: 62%;
    margin-top: -12%;
    color: white;
    font-size: 110%;
    left: 34%;
}
.newmefourtwop2{
    position: relative;
    width: 60%;
    margin-top: 0;
    color: white;
    font-size: 110%;
    left: 34%;
}
.newmefourone{
    position: relative;
    margin-top: 30%;
}
.newmefourtwo{
    position: relative;
    margin-top: 37%;
}
.neironglogo1{
    position: absolute;
    width: 100%;
    height: 10%;
    background: url(../photo/logo1one.png);
    background-size: 100% 100%;
    top: 30%;
}
.neironglogo2{
     position: absolute;
    width: 100%;
    height: 10%;
    background: url(../photo/logo2one.png);
      background-size: 100% 100%;
    top: 40%;
}
.neironglogo3{
    position: absolute;
    left: 35%;
    width: 30%;
    height: 10%;
    background-color: red;
    top: 50%;
}
.neirongtitle{
    position: absolute;
    left: 10%;
    width: 80%;
    height: 15%;
    background-color: red;
    top: 10%;
}
.neirongend{
    position: absolute;
    left: 10%;
    width: 80%;
    height: 15%;
    background-color: red;
    top: 60%;
}
.sunthreeneirongsone{
    position: absolute;
    left: 10%;
    top: 10%;
    width: 80%;
    height: 75%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.newmethreejuxingone{
    position: absolute;
    z-index: 1000;
    top: 0;
    width: 100%;
    font-size: 125%;
    font-weight: 600;
    height: 100%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/newmebac.png');
    background-size: 100% 100%;
}
.newmethreejuxingtwo{
    position: absolute;
    z-index: 1000;
    top: 0;
    width: 100%;
    font-size: 125%;
    font-weight: 600;
    height: 100%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/newmebac.png');
    background-size: 100% 100%;
}
.newmethreejuxingthree{
    position: absolute;
    z-index: 1000;
    top: 0;
    width: 100%;
    font-size: 125%;
    font-weight: 600;
    height: 100%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/newmebac.png');
    background-size: 100% 100%;
}
.newmethreejuxingfour{
    position: absolute;
    z-index: 1000;
    top: 0;
    width: 100%;
    font-size: 125%;
    font-weight: 600;
    height: 100%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/newmebac.png');
    background-size: 100% 100%;
}
.newmethreejuxingfive{
    position: absolute;
    z-index: 1000;
    top: 0;
    width: 100%;
    font-size: 125%;
    font-weight: 600;
    height: 100%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/newmebac.png');
    background-size: 100% 100%;
}
.newmethreejuxingsix{
    position: absolute;
    z-index: 1000;
    top: 0;
    width: 100%;
    font-size: 125%;
    font-weight: 600;
    height: 100%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/newmebac.png');
    background-size: 100% 100%;
}
.neironglogo1one{
    position: absolute;
    width: 100%;
    height: 13%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo1one.png');
    background-size: 100% 100%;
    top: 30%;
}
.neironglogoonemengban{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10000;
}
.neironglogotwomengban{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10000;
}
.neironglogothreemengban{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10000;
}
.neironglogofourmengban{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10000;
}
.neironglogofivemengban{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10000;
}
.neironglogosixmengban{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10000;
}
.neironglogo1onep{
    position: absolute;
    z-index: 1000;
    width: 80%;
    left: 10%;
    height: 52%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo1onep.png');
    background-size: 100% 100%;
    top: 20%;
}
.neironglogo2one{
     position: absolute;
     width: 100%;
    height: 13%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo2one.png');
    background-size: 100% 100%;
    top: 44%;
}
.neironglogo2onep{
    position: absolute;
    z-index: 1000;
    width: 80%;
    left: 10%;
    height: 52%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo2onep.png');
    background-size: 100% 100%;
    top: 20%;
}
.neironglogo3one{
    position: absolute;
    width: 100%;
    height: 13%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo3one.png');
    background-size: 100% 100%;
    top: 58%;
}
.neironglogo3onep{
    position: absolute;
    z-index: 1000;
    width: 80%;
    left: 10%;
    height: 52%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo3onep.png');
    background-size: 100% 100%;
    top: 20%;
}
.neirongtitleone{
    position: absolute;
    width: 100%;
    height: 20%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/neirongtitleone.png');
    background-size: 100% 100%;
    top: 8%;
}
.neirongendone{
    position: absolute;
    width: 100%;
    height: 13%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/neirongendone.png');
    background-size: 100% 100%;
    top: 72%;
}

.neironglogo1two{
    position: absolute;
    width: 100%;
    height: 13%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo1two.png');
    background-size: 100% 100%;
    top: 30%;
}
.neironglogo1twop{
    position: absolute;
    z-index: 1000;
    width: 80%;
    left: 10%;
    height: 52%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo1twop.png');
    background-size: 100% 100%;
    top: 20%;
}
.neironglogo2two{
     position: absolute;
     width: 100%;
    height: 13%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo2two.png');
    background-size: 100% 100%;
    top: 44%;
}
.neironglogo2twop{
    position: absolute;
    z-index: 1000;
    width: 80%;
    left: 10%;
    height: 52%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo2twop.png');
    background-size: 100% 100%;
    top: 20%;
}
.neironglogo3two{
    position: absolute;
    width: 100%;
    height: 13%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo3two.png');
    background-size: 100% 100%;
    top: 58%;
}
.neironglogo3twop{
    position: absolute;
    z-index: 1000;
    width: 80%;
    left: 10%;
    height: 52%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo3twop.png');
    background-size: 100% 100%;
    top: 20%;
}
.neirongtitletwo{
    position: absolute;
    width: 100%;
    height: 15%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/neirongtitletwo.png');
    background-size: 100% 100%;
    top: 8%;
}
.neirongendtwo{
    position: absolute;
    width: 100%;
    height: 16%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/neirongendtwo.png');
    background-size: 100% 100%;
    top: 72%;
}

.neironglogo3{
    position: absolute;
    left: 35%;
    width: 30%;
    height: 10%;
    background-color: red;
    top: 50%;
}
.neironglogo1three{
    position: absolute;
    width: 100%;
    height: 13%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo1three.png');
    background-size: 100% 100%;
    top: 30%;
}
.neironglogo1threep{
    position: absolute;
    z-index: 1000;
    width: 80%;
    left: 10%;
    height: 52%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo1threep.png');
    background-size: 100% 100%;
    top: 20%;
}
.neironglogo2three{
     position: absolute;
     width: 100%;
    height: 13%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo2three.png');
    background-size: 100% 100%;
    top: 44%;
}
.neironglogo2threep{
    position: absolute;
    z-index: 1000;
    width: 80%;
    left: 10%;
    height: 52%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo2threep.png');
    background-size: 100% 100%;
    top: 20%;
}
.neironglogo3three{
    position: absolute;
    width: 100%;
    height: 13%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo3three.png');
    background-size: 100% 100%;
    top: 58%;
}
.neironglogo3threep{
    position: absolute;
    z-index: 1000;
    width: 80%;
    left: 10%;
    height: 52%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo3threep.png');
    background-size: 100% 100%;
    top: 20%;
}
.neirongtitlethree{
    position: absolute;
    width: 100%;
    height: 15%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/neirongtitlethree.png');
    background-size: 100% 100%;
    top: 8%;
}
.neirongendthree{
    position: absolute;
    width: 100%;
    height: 16%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/neirongendthree.png');
    background-size: 100% 100%;
    top: 72%;
}

.neironglogo1four{
    position: absolute;
    width: 65%;
    height: 15%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo1four.png');
    background-size: 100% 100%;
    top: 33%;
}
.neironglogo1fourp{
    position: absolute;
    z-index: 1000;
    width: 80%;
    left: 10%;
    height: 52%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo1fourp.png');
    background-size: 100% 100%;
    top: 20%;
}
.neironglogo2four{
    position: absolute;
    width: 80%;
    right: 0;
    height: 19%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo2four.png');
    background-size: 100% 100%;
    top: 49%;
}
.neironglogo2fourp{
    position: absolute;
    z-index: 1000;
    width: 80%;
    left: 10%;
    height: 52%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo2fourp.png');
    background-size: 100% 100%;
    top: 20%;
}

.neirongtitlefour{
    position: absolute;
    width: 100%;
    height: 24%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/neirongtitlefour.png');
    background-size: 100% 100%;
    top: 8%;
}
.neirongendfour{
    position: absolute;
    width: 100%;
    height: 17%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/neirongendfour.png');
    background-size: 100% 100%;
    top: 72%;
}

.neironglogo1five{
    position: absolute;
    width: 100%;
    height: 13%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo1five.png');
    background-size: 100% 100%;
    top: 32%;
}
.neironglogo1fivep{
    position: absolute;
    z-index: 1000;
    width: 80%;
    left: 10%;
    height: 52%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo1fivep.png');
    background-size: 100% 100%;
    top: 20%;
}
.neironglogo2five{
    position: absolute;
    width: 100%;
    height: 13%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo2five.png');
    background-size: 100% 100%;
    top: 46%;
}
.neironglogo2fivep{
    position: absolute;
    z-index: 1000;
    width: 80%;
    left: 10%;
    height: 52%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo2fivep.png');
    background-size: 100% 100%;
    top: 20%;
}

.neirongtitlefive{
    position: absolute;
    width: 100%;
    height: 16%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/neirongtitlefive.png');
    background-size: 100% 100%;
    top: 8%;
}
.neirongendfive{
    position: absolute;
    width: 100%;
    height: 16%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/neirongendfive.png');
    background-size: 100% 100%;
    top: 72%;
}

.neironglogo1six{
    position: absolute;
    width: 100%;
    height: 14%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo1six.png');
    background-size: 100% 100%;
    top: 30%;
}
.neironglogo1sixp{
    position: absolute;
    z-index: 1000;
    width: 80%;
    left: 10%;
    height: 52%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo1sixp.png');
    background-size: 100% 100%;
    top: 20%;
}
.neironglogo2six{
    position: absolute;
    width: 100%;
    height: 13%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo2six.png');
    background-size: 100% 100%;
    top: 44%;
}
.neironglogo2sixp{
    position: absolute;
    z-index: 1000;
    width: 80%;
    left: 10%;
    height: 52%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo2sixp.png');
    background-size: 100% 100%;
    top: 20%;
}
.neironglogo3six{
    position: absolute;
    width: 100%;
    height: 13%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo3six.png');
    background-size: 100% 100%;
    top: 58%;
}
.neironglogo3sixp{
    position: absolute;
    z-index: 1000;
    width: 80%;
    left: 10%;
    height: 52%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/logo3sixp.png');
    background-size: 100% 100%;
    top: 20%;
}
.neirongtitlesix{
    position: absolute;
    width: 100%;
    height: 18%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/neirongtitlesix.png');
    background-size: 100% 100%;
    top: 8%;
}
.neirongendsix{
    position: absolute;
    width: 100%;
    height: 15%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/neirongendsix.png');
    background-size: 100% 100%;
    top: 72%;
}