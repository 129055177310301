.myshare {
	position:relative;
	padding:0;
	margin:0;
	height:90%;
	.course-header {
        padding: 8px 10px;
        display: flex;
        background-color: white;
        margin-bottom: 15px;
        .header-name {
            flex: 1;
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            color: #000000;
            .header-link {
                display: inline-block;
                transition: color 300ms ease;
            }
            .header-link:active {
                color: #3385ff;
            }
        }
    }
    .head-left{
    position:relative;
    top:2px;
    left:20%;
    width:60%;
    height:30px;
    overflow:hidden;
    .head-active{
    background:#096dd9;
    width:33%;
    height:100%;
    color:white;
    line-height:180%;
    text-align:center;
    border:1px solid #096dd9;
    float:left;
    }
    .head-common{
    border:1px solid #096dd9;
    width:33%;
    height:100%;
    color:#096dd9;
    line-height:180%;
    text-align:center;
    display:inline-block;
    float:left;
    }
    }
    .head-right{
    font-size:16px;
    width:100%;
    text-align:center;
    margin-bottom:10px;
    }
	.share-content{
	height:100%;
	position:relative;
	top:0px;
	background:#f5f5f5;
	padding:10px;
	overflow:auto;
	.item-content {
	margin:5px 0;
	width:90%;
	height:60px;
	margin-left:5%;
    background:white;
    border-radius:5px;
    position:relative;
    .item-status-circle-red {
    width:20px;
    height:20px;
    position:absolute;
    top:20px;
    left:2px;
    border-radius:20px;
    background:#f5222d;
    }
    .item-status-circle-green {
    width:20px;
    height:20px;
    position:absolute;
    top:20px;
    left:2px;
    border-radius:20px;
    background:#52c41a;
    }
    .item-coursename {
    width:90%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position:absolute;
    top:5px;
    left:7%;
    font-size:14px;
    }
    .item-time {
    position:absolute;
    top:38px;
    left:8%;
    font-size:14px;
    color:#cccccc;
    }
    .item-friend-isbuy {
    position:absolute;
    top:38px;
    right:10px;
    font-size:14px;
    color:#ffc53d;

    }

	}
	}
}
