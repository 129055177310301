.shengcheng{
    position: absolute;
    z-index: 10000;
    top: 30%;
    left: 0;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
}
.zhengshutitle{
    position: absolute;
    background: url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/photo/zhengshuwancheng.png");
    z-index: 10000;
    top:10%;
    width: 100%;
    padding-bottom: 68%;
    background-size: 100% 100%;
}
.zhengshumengban{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10001;
}
.wenzi{
    position: absolute;
}