.ourIdea {
  width: 100%;
  height: 100%;
  background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/home/bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .header, .content { width: 320px; }
  .header {
    display: flex;
    height: 55px;
    padding-top: 30px;
    margin: 0 auto 20px;
    .home {
      width: 25px;
      height: 25px;
      margin-right: 30px;
      background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/header/home.png");
      background-repeat: no-repeat;
      background-size: 100%;
      transition: all 500ms ease;
    }
    .title {
      flex: 1;
      height: 25px;
      line-height: 25px;
      color: white;
      font-size: 23px;
      font-family: "Songti SC";
      font-weight: bolder;
      text-align: right;
    }
    .home:active { transform: scale(.65); }
  }
  .content {
    margin: 0 auto;
    border: 2px solid white;
    height: calc(100% - 105px);
    position: relative;
    .container-1 {
      .info_list {
        width: 280px;
        margin: 50px auto 0;
        li {
          display: flex;
          color: white;
          margin-bottom: 30px;
          .name {
            width: 100px;
            height: 40px;
            line-height: 40px;
            background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/header/text-box-1.png");
            background-repeat: no-repeat;
            background-size: 100%;
            font-weight: bolder;
            font-family: "PingFang SC";
            margin-right: 10px;
            font-size: 20px;
            padding-right: 24px;
            text-align: center;
          }
          .detail {
            flex: 1;
            padding-top: 10px;
            font-size: 16px;
            color: #DDDDDD;
          }
        }
      }
    }
    .container-2 {
      .info_list {
        width: 200px;
        margin: 50px auto 0;
        li {
          color: white;
          margin-bottom: 20px;
          .name {
            width: 150px;
            height: 60px;
            line-height: 38px;
            text-align: center;
            font-weight: bolder;
            font-size: 18px;
            margin: 0 auto 10px;
            background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/header/text-box-2.png");
            background-repeat: no-repeat;
            background-size: 100%;
          }
          .detail {
            text-align: center;
            font-size: 16px;
            color: #DDDDDD;
          }
        }
        li:last-child {
          .name {
            font-size: 16px;
          }
        }
      }
    }
    .container-3 {
      .info_list {
        width: 280px;
        margin: 50px auto 0;
        li {
          display: flex;
          color: white;
          margin-bottom: 25px;
          .icon {
            width: 80px;
            height: 80px;
            text-align: center;
            line-height: 30px;
            padding: 25px 0;
            margin-right: 15px;
            background-repeat: no-repeat;
            background-size: 100%;
            font-weight: bolder;
            font-size: 22px;
          }
          .icon-1 {
            background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/header/circular-1.png");
          }
          .icon-2 {
            background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/header/circular-2.png");
          }
          .icon-3 {
            background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/header/circular-3.png");
          }
          .detail {
            flex: 1;
            line-height: 30px;
            height: 80px;
            padding: 25px 0;
            font-size: 16px;
          }
        }
      }
    }
    .toNext, .toTop {
      position: absolute;
      bottom: 0;
      width: 50px;
      height: 50px;
      background-size: 50%;
      background-repeat: no-repeat;
      background-position: 50%;
      transition: all 500ms ease;
    }
    .toTop {
      background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/header/toTop.png");
      left: 0;
    }
    .toNext {
      background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/header/toNext.png");
      right: 0;
    }
    .toNext:active, .toTop:active {
      transform: scale(.65);
    }
  }
}