
	.searchTitle{
	margin: 5px 5%;
	color: #666666;
      }

	.courses {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    list-style: none;
    background: white;
    padding: 10px;
    .course-item {
      padding: 10px;
      width: 50%;
      background-color: white;
      transition: background-color 400ms ease;
      .course-index {
        img {
          width: 100%;
          height: 100%;
        }
        margin-bottom: 10px;
      }
      .lessonName, .lessonPrice {
        height: 20px;
        line-height: 20px;
        color: #000000;
        font-size: 13px;
        margin-bottom: 0;
      }
      .lessonPrice {
        color: red;
      }
    }
    .course-item:active {
      background-color: whitesmoke;
    }
  }

