
.download_certificate {
	padding: 5px 15px 0;
	.title {
		height: 30px;
		line-height: 30px;
		text-align: center;
		margin-bottom: 15px;
	}
	.inputGroup {
		display: flex;
		margin-bottom: 10px;
		.input {
			flex: 1;
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
			border-right: none;
		}
		.btn {
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
		}
	}
}
