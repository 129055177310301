.loading-content {
  padding: 20px;
  background-color: rgba(100, 100, 100, .8);
  border-radius: 5px;
  backdrop-filter: blur(20px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 2px 2px 10px #AAAAAA;
}