.login {
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    position: relative;
    background-color: white;
    .login-content {
        width: 100%;
    }
    .form-title {
        line-height: 30px;
        padding: 20% 0 17% 15px;
        background-color: white;
        margin: 0;
        text-align: center;
    }
    .btn {
        width: calc(100% - 20px);
        margin: 0 10px;
    }
    .agree {
        display: flex;
        line-height: 23px;
        padding: 10px 15px;
        font-size: 16px;
        color: #333333;
    }
    .other-login {
        margin-top: 17%;
        display: flex;
        .login-type {
            flex: 1;
            .login-type-container {
                text-align: center;
                &>div {
                    margin: 0 auto 10px;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    font-size: 24px;
                    border-radius: 50%;
                    padding: 11px;
                    border: 1px solid #DDDDDD;
                    transition: background-color 300ms ease;
                    i {
                        width: 26px;
                        height: 26px;
                        line-height: 26px;
                        display: block;
                    }
                }
                &>div:active {
                    background-color: #DDDDDD;
                }
                .login-message {
                    color: wheat;
                }
                .login-wechat {
                    color: yellowgreen;
                }
                &>p {
                    font-size: 17px;
                }
            }
        }
    }
}