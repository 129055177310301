.course-home {
	width: 100%;
	position: relative;
	background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile/home/background.png");
	background-size: 100%;
	padding-top: 20px;
	overflow-x: hidden;
	user-select: none;
	.logout {
		position: absolute;
		right: 20px;
		top: 40px;
		width: 30px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		font-size: 18px;
		border-radius: 50%;
		transition: color 300ms ease;
		font-weight: bold;
	}
	img {
		user-select: none;
		-webkit-user-drag: none;
		pointer-events: none;
	}
	.logo {
		width: 20%;
		height: auto;
		margin: 0 auto 13%;
		img {
			width: 100%;
		}
	}
	.plane {
		width: 80%;
		height: auto;
		margin: 0 auto 22%;
		img {
			width: 100%;
		}
	}
	.icons {
		display: flex;
		width: 90%;
		margin: 0 auto 65%;
		.icon-item {
			flex: 1;
			img {
				display: block;
				margin: 0 auto;
				width: 60%;
			}
			transition: all 400ms ease;
		}
		.icon-item:active {
			transform: scale(.9);
		}
	}
	.courses {
		height: 200%;
		position: relative;
		.course-item {
			position: absolute;
			transition: all 400ms ease;
			img {
				width: 100%;
			}
		}
		.course-1 {
		    opcitiy:20;
			width: 50%;
			top: 1.2%;
		}
		.course-6 {
			top: 18%;
			right: 0%;
			width: 50%;
		}
		.course-3 {
			top: 24%;
			left: 0;
			width: 50%;
		}
		.course-7 {
			top: 40%;
			right: 2%;
			width: 50%;
		}
		.course-5 {
			top: 36%;
			left: 0;
			width: 50%;
		}
		.course-2 {
			top: 42%;
			right: 0;
			width: 58%;
		}
		.course-9 {
			top: 28%;
			left: 0;
			width: 50%;
		}
		.course-8 {
			top: 61%;
			right: -1%;
			width: 50%;
		}
		.course-4 {
			top: 51%;
			left: 0;
			width: 50%;
		}
		.user-central {
		    top:-2%;
			right: 0;
			width: 47%;
		}
		.course-footer {
			position: absolute;
			left:0;
			bottom: 0;
			height: 10%;
			width: 100%;
			background: rgb(244, 205, 72);
		}
		.course-item:active {
			transform: scale(.85);
		}
	}
}
