.dakehubacgif{
    position: absolute;
    width: 100%;
    height: 100%;
}
.dakehuone{
    position: absolute;
    left: 8%;
    top: 56%;
    width: 6%;
    height: 18%;
}
.dakehutwo{
    position: absolute;
    left: 18%;
    top: 56%;
    width: 6%;
    height: 18%;
}
.dakehuthree{
    position: absolute;
    left: 29%;
    top: 56%;
    width: 6%;
    height: 18%;
}
.dakehufour{
    position: absolute;
    left: 41%;
    top: 56%;
    width: 6%;
    height: 18%;
}
.dakehufive{
    position: absolute;

    left: 52%;
    top: 56%;
    width: 6%;
    height: 18%;
}
.dakehusix{
    position: absolute;
    left: 63%;
    top: 56%;
    width: 6%;
    height: 18%;
}
.dakehuseven{
    position: absolute;
    left: 74%;
    top: 56%;
    width: 6%;
    height: 18%;
}
.dakehueight{
    position: absolute;
    left: 85%;
    top: 56%;
    width: 6%;
    height: 18%;
}
.dakehuonebac{
    width: 100%;
    height: 100%;
    background: url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/dakehu/dakehuone.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.dakehutwobac{
    width: 100%;
    height: 100%;
    background: url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/dakehu/dakehutwo.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.dakehuthreebac{
    width: 100%;
    height: 100%;
    background: url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/dakehu/dakehuthree.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.dakehufourbac{
    width: 100%;
    height: 100%;
    background: url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/dakehu/dakehufour.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.dakehufivebac{
    width: 100%;
    height: 100%;
    background: url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/dakehu/dakehufive.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.dakehusixbac{
    width: 100%;
    height: 100%;
    background: url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/dakehu/dakehusix.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.dakehusevenbac{
    width: 100%;
    height: 100%;
    background: url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/dakehu/dakehuseven.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.dakehueightbac{
    width: 100%;
    height: 100%;
    background: url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/dakehu/dakehueight.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.dakehutui{
    position: absolute;
    top: 4%;
    left: 7%;
    width: 10%;
    padding-bottom: 10%;
}
.dakehucourse{
    position: absolute;
    top: 80%;
    left: 12%;
    width: 24%;
    height: 4%;
}
.dakehuteacher{
    position: absolute;
    top: 80%;
    left: 40%;
    width: 24%;
    height: 4%;;
}
.dakehucoursebac{
    width: 100%;
    height: 100%;
    background: url(../photo/dakehucourse.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.dakehuteacherbac{
    width: 100%;
    height: 100%;
    background: url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/dakehu/jiangshibac.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.dakehujiangshi{
    position: absolute;
    top: 2%;
    left: 3%;
    width: 94%;
    padding-bottom: 149%;
}
.dakehuonecourseling{
    position: absolute;
    left: 8%;
    top: 49.5%;
    width: 62%;
    height: 4%;
}
.dakehuonecourseyi{
    position: absolute;
    left: 8%;
    top: 55.5%;
    width: 62%;
    height: 4%;
}
.dakehuonecourseer{
    position: absolute;
    left: 8%;
    top: 61.5%;
    width: 62%;
    height: 4%;

}
.dakehuonecoursesan{
    position: absolute;
    left: 8%;
    top: 67%;
    width: 62%;
    height: 4%;

}
.dakehuonecoursesi{
    position: absolute;
    left: 8%;
    top: 73%;
    width: 62%;
    height: 4%;

}
.dakehuonecoursewu{
    position: absolute;
    left: 8%;
    top: 79%;
    width: 62%;
    height: 4%;

}
.dakehuonecourseliu{
    position: absolute;
    left: 8%;
    top: 85%;
    width: 62%;
    height: 4%;

}
.dakehutwocourseyi{
    position: absolute;
    left: 8%;
    top: 48%;
    width: 75%;
    height: 4%;

}
.dakehutwocourseer{
    position: absolute;
    left: 8%;
    top: 53.5%;
    width: 75%;
    height: 4%;

}
.dakehutwocoursesan{
    position: absolute;
    left: 8%;
    top: 58.5%;
    width: 75%;
    height: 4%;

}
.dakehutwocoursesi{
    position: absolute;
    left: 8%;
    top: 64%;
    width: 75%;
    height: 4%;

}
.dakehutwocoursewu{
    position: absolute;
    left: 8%;
    top: 69%;
    width: 75%;
    height: 4%;

}
.dakehutwocourseliu{
    position: absolute;
    left: 8%;
    top: 74.5%;
    width: 83%;
    height: 4%;

}
.dakehutwocourseqi{
    position: absolute;
    left: 8%;
    top: 80%;
    width: 65%;
    height: 3%;

}
.dakehutwocourseba{
    position: absolute;
    left: 8%;
    top: 85.5%;
    width: 73%;
    height: 3%;
}
.dakehutwonext{
    position: absolute;
    right: 5%;
    top: 92%;
    width: 20%;
    height: 4%;
}
.dakehutwoback{
    position: absolute;
    left: 5.5%;
    top: 92.5%;
    width: 20%;
    height: 4%;
}
.dakehutwocoursejiu{
    position: absolute;
    left: 8%;
    top: 48.5%;
    width: 62%;
    height: 3%;

}
.dakehutwocourseshi{
    position: absolute;
    left: 8%;
    top: 54%;
    width: 62%;
    height: 3%;

}
.dakehutwocourseshiyi{
    position: absolute;
    left: 8%;
    top: 59.5%;
    width: 67%;
    height: 3%;

}
.dakehutwocourseshier{
    position: absolute;
    left: 8%;
    top: 64.5%;
    width: 67%;
    height: 3%;

}
.dakehutwocourseshisan{
    position: absolute;
    left: 8%;
    top: 69.5%;
    width: 67%;
    height: 3%;

}
.dakehutwocourseshisi{
    position: absolute;
    left: 8%;
    top: 75%;
    width: 62%;
    height: 3%;

}
.dakehutwocourseshiwu{
    position: absolute;
    left: 8%;
    top: 80%;
    width: 62%;
    height: 3%;

}
.dakehutwocourseshiliu{
    position: absolute;
    left: 8%;
    top: 85%;
    width: 62%;
    height: 4%;
}
.dakehuthreecourseyi{
    position: absolute;
    left: 8%;
    top: 47.5%;
    width: 62%;
    height: 4%;

}
.dakehuthreecourseer{
    position: absolute;
    left: 8%;
    top: 51.5%;
    width: 62%;
    height: 4%;

}
.dakehuthreecoursesan{
    position: absolute;
    left: 8%;
    top: 56%;
    width: 62%;
    height: 4%;
}
.dakehuthreecoursesi{
    position: absolute;
    left: 8%;
    top: 60.5%;
    width: 62%;
    height: 4%;

}
.dakehuthreecoursewu{
    position: absolute;
    left: 8%;
    top: 64.5%;
    width: 62%;
    height: 4%;
}
.dakehuthreecourseliu{
    position: absolute;
    left: 8%;
    top: 69%;
    width: 65%;
    height: 4%;

}
.dakehuthreecourseqi{
    position: absolute;
    left: 8%;
    top: 73.5%;
    width: 62%;
    height: 4%;

}
.dakehuthreecourseba{
    position: absolute;
    left: 8%;
    top: 77.5%;
    width: 64%;
    height: 4%;
}
.dakehuthreecoursejiu{
    position: absolute;
    left: 8%;
    top: 82%;
    width: 62%;
    height: 4%;

}
.dakehuthreecourseshi{
    position: absolute;
    left: 8%;
    top: 86.5%;
    width: 64%;
    height: 4%;

}
.dakehuthreecourseshiyi{
    position: absolute;
    left: 8%;
    top: 91%;
    width: 71%;
    height: 4%;

}
.dakehufournext{
    position: absolute;
    right: 5%;
    top: 92%;
    width: 20%;
    height: 4%;
}
.dakehufourback{
    position: absolute;
    left: 5.5%;
    top: 92.5%;
    width: 20%;
    height: 4%;
}
.dakehufourcourseyi{
    position: absolute;
    left: 8%;
    top: 47.5%;
    width: 62%;
    height: 3%;
}

.dakehufourcourseer{
    position: absolute;
    left: 8%;
    top: 52%;
    width: 62%;
    height: 3%;
}
.dakehufourcoursesan{
    position: absolute;
    left: 8%;
    top: 56.5%;
    width: 62%;
    height: 3%;
}
.dakehufourcoursesi{
    position: absolute;
    left: 8%;
    top: 60.5%;
    width: 62%;
    height: 3%;
}
.dakehufourcoursewu{
    position: absolute;
    left: 8%;
    top: 65%;
    width: 62%;
    height: 3%;
}
.dakehufourcourseliu{
    position: absolute;
    left: 8%;
    top: 69.5%;
    width: 62%;
    height: 3%;
}
.dakehufourcourseqi{
    position: absolute;
    left: 8%;
    top: 73.5%;
    width: 62%;
    height: 3%;
}
.dakehufourcourseba{
    position: absolute;
    left: 8%;
    top: 78%;
    width: 77%;
    height: 3%;
}
.dakehufourcoursejiu{
    position: absolute;
    left: 8%;
    top: 82%;
    width: 62%;
    height: 3%;
}
.dakehufourcourseshi{
    position: absolute;
    left: 8%;
    top: 86.5%;
    width: 62%;
    height: 3%;
}
.dakehufourcourseshiyi{
    position: absolute;
    left: 8%;
    top: 47.5%;
    width: 62%;
    height: 3%;
}
.dakehufourcourseshier{
    position: absolute;
    left: 8%;
    top: 52%;
    width: 62%;
    height: 3%;
}
.dakehufourcourseshisan{
    position: absolute;
    left: 8%;
    top: 56.5%;
    width: 62%;
    height: 3%;
}
.dakehufourcourseshisi{
    position: absolute;
    left: 8%;
    top: 60.5%;
    width: 62%;
    height: 3%;
}

.dakehufourcourseshiwu{
    position: absolute;
    left: 8%;
    top: 65%;
    width: 62%;
    height: 3%;
}
.dakehufourcourseshiliu{
    position: absolute;
    left: 8%;
    top: 69%;
    width: 62%;
    height: 3%;
}
.dakehufourcourseshiqi{
    position: absolute;
    left: 8%;
    top: 73.5%;
    width: 62%;
    height: 3%;
}
.dakehufourcourseshiba{
    position: absolute;
    left: 8%;
    top: 77.5%;
    width: 62%;
    height: 3%;
}
.dakehufourcourseshijiu{
    position: absolute;
    left: 8%;
    top: 82%;
    width: 62%;
    height: 3%;
}
.dakehufourcourseershi{
    position: absolute;
    left: 8%;
    top: 86.5%;
    width: 62%;
    height: 3%;
}
.dakehufivenext{
    position: absolute;
    right: 5%;
    top: 92%;
    width: 20%;
    height: 4%;
}
.dakehufiveback{
    position: absolute;
    left: 5.5%;
    top: 92.5%;
    width: 20%;
    height: 4%;
}
.dakehufivecourseyi{
    position: absolute;
    left: 8%;
    top: 48.5%;
    width: 69%;
    height: 3%;
}
.dakehufivecourseer{
    position: absolute;
    left: 8%;
    top: 53.5%;
    width: 69%;
    height: 3%;
}
.dakehufivecoursesan{
    position: absolute;
    left: 8%;
    top: 58%;
    width: 69%;
    height: 3%;
}
.dakehufivecoursesi{
    position: absolute;

    left: 8%;
    top: 63%;
    width: 69%;
    height: 3%;
}
.dakehufivecoursewu{
    position: absolute;

    left: 8%;
    top: 68%;
    width: 69%;
    height: 3%;
}
.dakehufivecourseliu{
    position: absolute;

    left: 8%;
    top: 72.5%;
    width: 69%;
    height: 3%;
}
.dakehufivecourseqi{
    position: absolute;

    left: 8%;
    top: 77.5%;
    width: 69%;
    height: 3%;
}
.dakehufivecourseba{
    position: absolute;
    left: 8%;
    top: 82%;
    width: 69%;
    height: 3%;
}
.dakehufivecoursejiu{
    position: absolute;
    left: 8%;
    top: 87%;
    width: 69%;
    height: 3%;
}
.dakehufivecourseshi{
    position: absolute;
    left: 8%;
    top: 48.5%;
    width: 69%;
    height: 3%;
}
.dakehufivecourseshiyi{
    position: absolute;
    left: 8%;
    top: 53.5%;
    width: 77%;
    height: 3%;
}
.dakehufivecourseshier{
    position: absolute;
    left: 8%;
    top: 58%;
    width: 69%;
    height: 3%;
}
.dakehufivecourseshisan{
    position: absolute;
    left: 8%;
    top: 63%;
    width: 73%;
    height: 3%;
}
.dakehufivecourseshisi{
    position: absolute;

    left: 8%;
    top: 67.5%;
    width: 69%;
    height: 3%;
}
.dakehufivecourseshiwu{
     position: absolute;
     left: 8%;
     top: 72.5%;
     width: 86%;
     height: 3%;
 }
.dakehufivecourseshiliu{
    position: absolute;

    left: 8%;
    top: 77.5%;
    width: 69%;
    height: 3%;
}
.dakehufivecourseshiqi{
    position: absolute;
    left: 8%;
    top: 82%;
    width: 78%;
    height: 3%;
}
.dakehufivecourseshiba{
    position: absolute;
    left: 8%;
    top: 87%;
    width: 69%;
    height: 3%;
}
.dakehusixnext{
    position: absolute;
    right: 5%;
    top: 92%;
    width: 20%;
    height: 4%;
}
.dakehusixback{
    position: absolute;
    left: 5.5%;
    top: 92.5%;
    width: 20%;
    height: 4%;
}

.dakehusixcourseyi{
    position: absolute;

    left: 8%;
    top: 47.5%;
    width: 69%;
    height: 3%;
}
.dakehusixcourseer{
    position: absolute;

    left: 8%;
    top: 51.5%;
    width: 69%;
    height: 3%;
}
.dakehusixcoursesan{
    position: absolute;

    left: 8%;
    top: 55%;
    width: 69%;
    height: 3%;
}
.dakehusixcoursesi{
    position: absolute;
    left: 8%;
    top: 59%;
    width: 69%;
    height: 3%;
}
.dakehusixcoursewu{
    position: absolute;

    left: 8%;
    top: 62.5%;
    width: 69%;
    height: 3%;
}
.dakehusixcourseliu{
    position: absolute;

    left: 8%;
    top: 66.5%;
    width: 69%;
    height: 3%;
}
.dakehusixcourseqi{
    position: absolute;

    left: 8%;
    top: 70%;
    width: 69%;
    height: 3%;
}
.dakehusixcourseba{
    position: absolute;

    left: 8%;
    top: 73.5%;
    width: 69%;
    height: 3%;
}
.dakehusixcoursejiu{
    position: absolute;

    left: 8%;
    top: 77.5%;
    width: 69%;
    height: 3%;
}
.dakehusixcourseshi{
    position: absolute;

    left: 8%;
    top: 81%;
    width: 69%;
    height: 3%;
}
.dakehusixcourseshiyi{
     position: absolute;
     left: 8%;
     top: 84.5%;
     width: 69%;
     height: 3%;
 }
.dakehusixcourseshier{
    position: absolute;

    left: 8%;
    top: 88.5%;
    width: 69%;
    height: 3%;
}
.dakehusixcourseshisan{
    position: absolute;

    left: 8%;
    top: 47.5%;
    width: 69%;
    height: 3%;
}

.dakehusixcourseshisi{
    position: absolute;

    left: 8%;
    top: 51.5%;
    width: 82%;
    height: 3%;
}
.dakehusixcourseshiwu{
    position: absolute;

    left: 8%;
    top: 55.5%;
    width: 87%;
    height: 3%;
}
.dakehusixcourseshiliu{
    position: absolute;

    left: 8%;
    top: 59.5%;
    width: 69%;
    height: 3%;
}
.dakehusixcourseshiqi{
     position: absolute;
     left: 8%;
     top: 64%;
     width: 69%;
     height: 3%;
 }
.dakehusixcourseshiba{
      position: absolute;
      left: 8%;
      top: 68%;
      width: 69%;
      height: 3%;
  }
.dakehusixcourseshijiu{
    position: absolute;

    left: 8%;
    top: 72%;
    width: 69%;
    height: 3%;
}
.dakehusixcourseershi{
    position: absolute;

    left: 8%;
    top: 76%;
    width: 69%;
    height: 3%;
}
.dakehusixcourseershiyi{
    position: absolute;

    left: 8%;
    top: 80%;
    width: 69%;
    height: 3%;
}
.dakehusixcourseershier{
    position: absolute;

    left: 8%;
    top: 84%;
    width: 69%;
    height: 3%;
}
.dakehusixcourseershisan{
    position: absolute;

    left: 8%;
    top: 88%;
    width: 69%;
    height: 3%;
}
.dakehusevennext{
    position: absolute;
    right: 5%;
    top: 92%;
    width: 20%;
    height: 4%;
}
.dakehusevenback{
    position: absolute;
    left: 5.5%;
    top: 92.5%;
    width: 20%;
    height: 4%;
}

.dakehusevencourseyi{
    position: absolute;
    left: 8%;
    top: 48.5%;
    width: 69%;
    height: 3%;
}
.dakehusevencourseer{
    position: absolute;
    left: 8%;
    top: 52.5%;
    width: 69%;
    height: 3%;
}
.dakehusevencoursesan{
    position: absolute;

    left: 8%;
    top: 57%;
    width: 69%;
    height: 3%;
}
.dakehusevencoursesi{
    position: absolute;
    left: 8%;
    top: 61.5%;
    width: 69%;
    height: 3%;
}
.dakehusevencoursewu{
    position: absolute;
    left: 8%;
    top: 65.5%;
    width: 69%;
    height: 3%;
}
.dakehusevencourseliu{
     position: absolute;
     left: 8%;
     top: 70%;
     width: 69%;
     height: 3%;
 }
.dakehusevencourseqi{
      position: absolute;
      left: 8%;
      top: 74.5%;
      width: 69%;
      height: 3%;
  }
.dakehusevencourseba{
       position: absolute;
       left: 8%;
       top: 79%;
       width: 69%;
       height: 3%;
   }
.dakehusevencoursejiu{
        position: absolute;
        left: 8%;
        top: 83%;
        width: 69%;
        height: 3%;
}
.dakehusevencourseshi{
    position: absolute;
    left: 8%;
    top: 87.5%;
    width: 73%;
    height: 3%;
}
.dakehusevencourseshiyi{
    position: absolute;
    left: 8%;
    top: 48.5%;
    width: 73%;
    height: 3%;
}
.dakehusevencourseshier{
    position: absolute;
    left: 8%;
    top: 53.5%;
    width: 69%;
    height: 3%;
}
.dakehusevencourseshisan{
    position: absolute;

    left: 8%;
    top: 58.5%;
    width: 78%;
    height: 3%;
}
.dakehusevencourseshisi{
    position: absolute;

    left: 8%;
    top: 63.5%;
    width: 69%;
    height: 3%;
}
.dakehusevencourseshiwu{
    position: absolute;

    left: 8%;
    top: 68%;
    width: 69%;
    height: 3%;
}
.dakehusevencourseshiliu{
    position: absolute;

    left: 8%;
    top: 73%;
    width: 69%;
    height: 3%;
}
.dakehusevencourseshiqi{
    position: absolute;

    left: 8%;
    top: 78%;
    width: 69%;
    height: 3%;
}
.dakehusevencourseshiba{
    position: absolute;
    left: 8%;
    top: 83%;
    width: 69%;
    height: 3%;
}

.dakehueightnext{
    position: absolute;
    right: 5%;
    top: 92%;
    width: 20%;
    height: 4%;
}
.dakehueightback{
    position: absolute;
    left: 5.5%;
    top: 92.5%;
    width: 20%;
    height: 4%;
}


.dakehueightcourseyi{
    position: absolute;
    left: 8%;
    top: 48%;
    width: 69%;
    height: 4%;
}
.dakehueightcourseer{
    position: absolute;
    left: 8%;
    top: 53%;
    width: 69%;
    height: 4%;
}
.dakehueightcoursesan{
    position: absolute;

    left: 8%;
    top: 58%;
    width: 69%;
    height: 4%;
}
.dakehueightcoursesi{
    position: absolute;

    left: 8%;
    top: 63%;
    width: 69%;
    height: 4%;
}
.dakehueightcoursewu{
    position: absolute;

    left: 8%;
    top: 68%;
    width: 69%;
    height: 4%;
}
.dakehueightcourseliu{
    position: absolute;

    left: 8%;
    top: 73%;
    width: 69%;
    height: 4%;
}
.dakehueightcourseqi{
    position: absolute;

    left: 8%;
    top: 78%;
    width: 69%;
    height: 4%;
}
.dakehueightcourseba{
    position: absolute;
    left: 8%;
    top: 84%;
    width: 69%;
    height: 4%;
}
.dakehueightcoursejiu{
    position: absolute;

    left: 8%;
    top: 48%;
    width: 69%;
    height: 4%;
}
.dakehueightcourseshi{
    position: absolute;

    left: 8%;
    top: 53%;
    width: 69%;
    height: 4%;
}
.dakehueightcourseshiyi{
    position: absolute;

    left: 8%;
    top: 58%;
    width: 69%;
    height: 4%;
}
.dakehueightcourseshier{
    position: absolute;

    left: 8%;
    top: 63%;
    width: 69%;
    height: 4%;
}
.dakehueightcourseshisan{
    position: absolute;

    left: 8%;
    top: 68%;
    width: 69%;
    height: 4%;
}
.dakehueightcourseshisi{
    position: absolute;

    left: 8%;
    top: 73%;
    width: 69%;
    height: 4%;
}
.dakehueightcourseshiwu{
    position: absolute;

    left: 8%;
    top: 78%;
    width: 69%;
    height: 4%;
}
.dakehueightcourseshiliu{
    position: absolute;

    left: 8%;
    top: 83%;
    width: 69%;
    height: 4%;
}
.dakehuvideo1{
    position: absolute;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/videobac.png');
    left: 6.5%;
    width: 87%;
    height: 26%;
    top: 10.5%;

}
.dakehuclose{
    position: absolute;
    top: 10%;
    width: 8%;
    right: 0%;
    font-size: 150%;
    text-align: center;
    color: white;
}
.wenti{
    position: absolute;
    background: url(../photo/ques.png);
    top:20%;
    width: 90%;
    left: 5%;
    padding-bottom: 60%;
    background-size: 100% 100%;
    z-index: 100;
}
.dakehuques{
    position: absolute;
    top: 22%;
    z-index: 1000;
    width: 80%;
    left: 10%;
    font-size: 150%;

}
.dakehuans1{
    position: absolute;
    top: 34%;
    z-index: 1000;
    width: 80%;
    left: 10%;
    font-size: 140%;
}
.dakehuans2{
    position: absolute;
    top: 38%;
    z-index: 1000;
    width: 80%;
    left: 10%;
    font-size: 140%;
}
.dakehuans3{
    position: absolute;
    top: 42%;
    z-index: 1000;
    width: 80%;
    left: 10%;
    font-size: 140%;
}
.dakehuans4{
    position: absolute;
    top: 46%;
    z-index: 1000;
    width: 80%;
    left: 10%;
    font-size: 140%;
}
.dakehuzhengshu{
    position: absolute;
    top: 86%;
    left: 49%;
    width: 17%;
    padding-bottom: 8%;
}
.zhengshutitle{
    position: absolute;
    background: url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/photo/zhengshuwancheng.png");
    z-index: 10000;
    top:10%;
    width: 100%;
    padding-bottom: 79%;
    background-size: 100% 100%;
}
.zhengshutp{
    position: absolute;
    top: 16%;
    width: 100%;
    font-size: 220%;
    text-align: center;
    z-index: 10002;
    font-weight: 600;
}
.zhengshuname{
    position: absolute;
    width: 100%;
    text-align: center;
    top: 29%;
    font-size: 120%;
    z-index: 10002;
}
.zhengshup1{
    position: absolute;
    width: 100%;
    text-align: center;
    top: 38%;
    font-size: 120%;
    z-index: 10002;
}
.zhengshup2{
    position: absolute;
    width: 100%;
    text-align: center;
    top: 43%;
    font-size: 120%;
    z-index: 10002;
}
.zhengshup3{
    position: absolute;
    width: 100%;
    text-align: center;
    top: 48%;
    font-size: 120%;
    z-index: 10002;
}
.zhengshue1{
    position: absolute;
    width: 100%;
    top: 58%;
    font-size: 120%;
    text-align: center;
    z-index: 10002;
}
.zhengshue2{
    position: absolute;
    width: 100%;
    top: 70%;
    font-size: 120%;
    text-align: center;
    z-index: 10002;
}
.wenziimg{
    width: 100%;
}
.zhengshutitlep{
    position: absolute;
    width: 100%;
    text-align: center;
    top: 3%;
}
.wenzi{
    position: absolute;
    top: 6%;
}
.dakehuziliao{
    position: absolute;
    top: 86%;
    left: 30%;
    width: 17%;
    padding-bottom: 8%;
}
.dakehuchengji{
    position: absolute;
    top: 86%;
    left: 10%;
    width: 17%;
    padding-bottom: 8%;
}
.dakehuyouxiang{
    position: absolute;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/dakehu/ziliao.png');
    z-index: 10004;
    top: 25%;
    width: 80%;
    left: 10%;
    padding-bottom: 57%;
    background-size: 100% 100%;
}
.youxiangmengban{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10001;
    background: #3c3a38;
    opacity: 0.4;
}
.youxianginput{
    position: absolute;
    z-index: 100002;
    left: 28%;
    top: 42%;
    width: 57%;
}
.youxiangqueren{
    position: absolute;
    z-index: 1000003;
    left: 30%;
    top: 48%;
    width: 40%;
    padding-bottom: 7%;
}
