.savePicture{
	position:relative;
	height:100%;
	background:#434343;
  .main-content{
   width:64%;
   margin-left:18%;
   position:relative;
   top:8%;
   border-radius:10px;
   overflow:hidden;
   .image-loading{
    padding:10px 20px;
    background:rgba(255,255,255,0.4);
    backdrop-filter:blur(20px);
    border-radius:5px;
    position:absolute;
    top:150px;
    left:50%;
    transform:translateX(-50%);
    .image-loading-icon{
	    width:30px;
	    height:30px;
	    margin: 0 auto 5px;
    }
    .image-loading-text{
    	width:80px;
	    text-align:center;
	    color:#1890ff;
	    }
   }
  }


  .bottom{
  position:absolute;
  bottom:0px;
  background:#ffffff;
  width:100%;
  height:20%;
  }
  .bottom-text{
  margin-top:2%;
  margin-bottom:-1%;
  width:100%;
  height:20%;
  float:left;
  font-size:12px;
  line-height:100%;
  span{
  float:left;
  color:#434343;
  margin-left:2%;
  }
  a{
  float:right;
  margin-right:2%
  }
  }
  .bottom-content{
  width:98%;
  height:80%;
  float:left;
  margin:0 0 0 1%;
  .bottom-content-item{
  position:relative;
  height:90%;
  width:18%;
  float:left;
  margin:0% 1% 0 1%;
  border-radius:10px;
  border:2px solid #cccccc;
  overflow:hidden;
  img{
  height:99%;
  width:99%;
  }
  }
  .active{
  border:2px solid #1890ff;
  }
  }
}