.propaganda {
  width: 100%;
  height: 100%;
  background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/home/bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  .content {
    width: 320px;
    height: calc(100% - 100px);
    border: 2px solid white;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    .logo {
      width: 155px;
      height: 100px;
      margin: 40px auto;
      background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/home/logo.png");
      background-repeat: no-repeat;
      background-size: 100%;
    }

    .slogan {
      width: 100%;
      height: 50px;
      margin-bottom: 60px;
      color: #DDDDDD;
      font-size: 25px;
      line-height: 50px;
      text-align: center;
      font-family: "Songti SC";
      font-weight: bold;
    }

    .btnGroups {
      width: 270px;
      margin: 0 auto;
      li {
        display: flex;
        margin-bottom: 40px;
        .btn {
          flex: 1;
          height: 64px;
          background-repeat: no-repeat;
          background-size: 100%;
          transition: all 500ms ease;
        }
        .btn-1, .btn-3 { margin-right: 20px; }
        .btn-1 {
          background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/home/btn-1.png");
        }
        .btn-2 {
          background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/home/btn-2.png");
        }
        .btn-3 {
          background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/home/btn-3.png");
        }
        .btn-4 {
          background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/home/btn-4.png");
        }
        .btn:active {
          transform: scale(.75);
        }
      }
    }

  }
}