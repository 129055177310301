.support {
  width: 100%;
  height: 100%;
  background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/home/bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .header, .content { width: 320px; }
  .header {
    display: flex;
    height: 55px;
    padding-top: 30px;
    margin: 0 auto 20px;
    .home {
      width: 25px;
      height: 25px;
      margin-right: 30px;
      background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/header/home.png");
      background-repeat: no-repeat;
      background-size: 100%;
      transition: all 500ms ease;
    }
    .title {
      flex: 1;
      height: 25px;
      line-height: 25px;
      color: white;
      font-size: 23px;
      font-family: "Songti SC";
      font-weight: bolder;
      text-align: right;
    }
    .home:active { transform: scale(.65); }
  }
  .content {
    margin: 0 auto;
    border: 2px solid white;
    height: calc(100% - 105px);
    position: relative;
    .container-1, .container-3 {
      width: 100%;
      height: 100%;
      overflow: hidden;
      .detail, .info {
        width: 280px;
        margin: 30px auto 0;
        line-height: 25px;
        color: white;
        font-size: 16px;
      }
      .videoContainer {
        width: 150px;
        height: 220px;
        margin: 10px auto;
      }
      .info {
        margin-top: 0;
      }
    }
    .container-2 {
      ul {
        width: 280px;
        margin: 30px auto 0;
        li {
          height: 30px;
          line-height: 30px;
          text-align: center;
          font-size: 18px;
          font-weight: bolder;
          margin-bottom: 10px;
          color: white;
        }
        li:last-child {
          margin: 0;
        }
      }
      .videoContainer {
          width: 150px;
          height: 220px;
          margin: 10px auto;
        }
      .video {
        width: 150px;
        height: 220px;
        margin: 10px auto;
      }
      .info {
        width: 280px;
        margin: 0 auto;
        line-height: 25px;
        color: white;
        font-size: 16px;
      }
    }


    .container-4 {
      .top {
        width: 280px;
        padding-top: 40px;
        margin: 0 auto 25px;
        display: flex;
        .left {
          width: 25px;
          margin-right: 10px;
          padding-top: 8px;
          img {width: 100%;}
        }
        .right {
          flex: 1;
          color: white;
          line-height: 30px;
          font-size: 16px;
        }
      }
      .center {
        width: 120px;
        margin: 0 auto 25px;
        img { width: 100% }
      }
      .bottom {
        position: relative;
        padding-left: 30px;
        img { width: 150px; }
        .info {
          position: absolute;
          color: white;
          top: 60px;
          left: 170px;
          font-size: 16px;
          margin: 0;
          height: 25px;
          line-height: 25px;
        }
      }
    }
    .toNext, .toTop {
      position: absolute;
      bottom: 0;
      width: 50px;
      height: 50px;
      background-size: 50%;
      background-repeat: no-repeat;
      background-position: 50%;
      transition: all 500ms ease;
    }
    .toTop {
      background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/header/toTop.png");
      left: 0;
    }
    .toNext {
      background-image: url("https://micro-weixun.oss-cn-beijing.aliyuncs.com/micro_mobile_propaganda/header/toNext.png");
      right: 0;
    }
    .toNext:active, .toTop:active {
      transform: scale(.65);
    }
  }
}