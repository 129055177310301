.father {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
}
.box {
    position: relative;
    height:100%;
}
.box .bg {
    height: 100%;
    position: absolute;
    top: 0px;
    left:0px;
    z-index: -999
}
.box .iconInclude{
    position: absolute;
    z-index:999;
    display:'inline-block';

}

.box .one{
    top: 85%;
    left: 28px;   
}

.box .two{
    top: 67%;
    left: 310px;   
}

.box .three{
    top: 66%;
    left: 919px;   
}
