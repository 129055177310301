.information{
  position:absolute;
  width:100%;
.information-header {
    font-size:12px;
    padding: 0px 10px;
    display: flex;
    background-color: #f5f5f5;
    margin-bottom:0px;
    .header-name {
      flex: 1;
      height: 30px;
      line-height: 30px;
      color: #000000;
      .header-link {
        display: inline-block;
        transition: color 300ms ease;
      }
      .header-link:active {
        color: #3385ff;
      }
    }
  }
  .information-content{
    width:100%;
    padding:20px 20px;
    .content-avatar{
      width:100%;
      display:flex;
      justify-content:center;
    }
    .change-information{
     width:100%;
      display:flex;
      justify-content:center;
      Button{
      margin-top:15px;
      background:#1890ff;
      color:white;
      font-size:12px;
      }
    }
    div{
    margin-top:10px
    }

  }
  }