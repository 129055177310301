.history {
    .course-header {
        padding: 8px 10px;
        display: flex;
        background-color: white;
        margin-bottom: 15px;
        .header-name {
            flex: 1;
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            color: #000000;
            .header-link {
                display: inline-block;
                transition: color 300ms ease;
            }
            .header-link:active {
                color: #3385ff;
            }
        }
    }
    .getMore {
        text-align: center;
        padding: 10px 0;
        line-height: 25px;
        height: 45px;
        margin-bottom: 20px;
    }
}