.exam {
	padding: 0 15px;
	.title {
		height: 30px;
		line-height: 30px;
		text-align: center;
		margin: 20px 0;
	}
	.progress-header {
		width: 100%;
		height: 20px;
		display: flex;
		margin-bottom: 5px;
		span {
			flex: 1;
			line-height: 20px;
		}
		.progress-header-information {
			text-align: right;
		}
	}
	.progress {
		width: 100%;
		height: 10px;
		margin-bottom: 10px;
		border-radius: 5px;
		border: 1px solid #EEEEEE;
		overflow: hidden;
		.complete {
			transition: all 400ms ease;
			height: 100%;
			background-color: #3385ff;
		}
	}
	.single, .choose {
		.question-header {
			font-size: 17px;
			user-select: none;
		}
		.options {
			padding: 0;
			li {
				list-style-type: none;
				padding: 10px 15px;
				border-bottom: 1px solid #EEEEEE;
				transition: all 400ms ease;
				user-select: none;
				margin-bottom: 6px;
				border-radius: 3px;
			}
			li:active {
				background-color: #EEEEEE;
			}
			li:last-child {
				margin-bottom: 0;
			}
		}
	}
	.ctrl-btn-group {
		margin-top: 10px;
		height: 40px;
		display: flex;
		.to-prev {
			border-right: 1px solid #EEEEEE;
		}
		.to-prev, .to-next {
			flex: 1;
			text-align: center;
			line-height: 40px;
			border-bottom: 1px solid #EEEEEE;
			transition: all 400ms ease;
			color: #3385ff;
			user-select: none;
		}
		.to-prev[disabled], .to-next[disabled] {
			color: #CCCCCC;
		}
		.to-prev:active, .to-next:active {
			background-color: #EEEEEE;
		}
		.to-prev[disabled]:active, .to-next[disabled]:active {
			background-color: #FFFFFF;
		}
	}
}
.scoreShow {
	.title {
		text-align: center;
		color: #333333;
		margin: 10px 0 0;
		padding: 5px 0;
		border-bottom: 1px solid #EEEEEE;
	}
	.score {
		width: 120px;
		height: 120px;
		line-height: 120px;
		text-align: center;
		user-select: none;
		border-radius: 50%;
		overflow: hidden;
		margin: 10px auto;
		color: white;
		box-shadow: 2px 5px 30px #EEEEEE;
		transition: all 400ms ease;
	}
	.content {
		border-top: 1px solid #EEEEEE;
		.getZs {
			border-bottom: 1px solid #EEEEEE;
			margin-bottom: 10px;
			padding: 10px 0;
			.getZs-btn {
				display: block;
				margin: 0 auto;
			}
			.tips {
				height: 20px;
				line-height: 20px;
				color: rgb(249, 164, 8);
				text-align: center;
				margin: 5px 0 0;
			}
		}
		.reExam {
			padding: 10px 0;
			button {
				display: block;
				margin: 0 auto;
			}
		}
	}
}
