.share{
  margin-top:10px;
  width:100%;
  display:flex;
   justify-content:center;
}
.shareWechat{
  width:50px;
  height:50px;
  border-radius:50px;
  margin:15px;
  }
.shareWechatCircle{
  width:50px;
  height:50px;
   border-radius:50px;
margin:15px;
}
.savePhoto{
  width:50px;
  height:50px;
   border-radius:50px;
margin:15px;
}
.course {
  padding-bottom: 100px;
  width: 100%;
   
  .course-header {
    padding: 8px 10px;
    display: flex;
    background-color: white;
    margin-bottom: 15px;
    .header-name {
      flex: 1;
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      color: #000000;
      .header-link {
        display: inline-block;
        transition: color 300ms ease;
      }
      .header-link:active {
        color: #3385ff;
      }
    }
  }
  .courseIcon {
    img {
      width: 100%;
      height: 100%;
    }
  }
  .block-title {
    height: 16px;
    line-height: 16px;
    margin-bottom: 10px;
    margin-left: 5px;
    font-size: 15px;
    color: #666666;
  }

  .lessonInfo {
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    .lessonName {
      height: 25px;
      line-height: 25px;
      margin-bottom: 10px;
      color: #000000;
    }
    .lessonDescription {
      margin-bottom: 6px;
    }
    .lessonPrice {
      line-height: 20px;
      margin-bottom: 0;
      color: red;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
  
  .video-list {
    .chapterName {
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      padding-left: 10px;
      margin-bottom: 5px;
      color: cornflowerblue;
      font-weight: bold;
    }
    .video {
      height: 50px;
      padding: 10px;
      border-radius: 5px;
      line-height: 30px;
      user-select: none;
      cursor: pointer;
      transition: all .4s;
      margin-bottom: 10px;
      font-size: 16px;
      .ok-play, .no-play {
        width: 100%;
        height: 30px;
        line-height: 30px;
        display: flex;
        align-items:center;
        .video-after, .video-state {
          width: 36px;
          height: 30px;
          padding: 5px 0;
          line-height: normal;
        }
        .video-after {
          margin-right: 10px;
        }
        .video-name {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .video-state {
          margin-left: 10px;
          svg {
            display: block;
            margin: 2px 10px;
          }
        }
        p {
          top: 0;
          right: 20px;
          height: 30px;
          line-height: 30px;
        }
      }
      .ok-play {
        color: #333333;
      }
      .no-play {
        color: #888888;
      }
    }
    .video-ok {
      background-color: white;
    }
    .video-no {
      background-color: #FAFAFA;
    }
    .video-ok:active {
      background-color: #EEEEEE;
    }
  }
  .mobile_footer{
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 20px 0;
    background-color: white;
    z-index: 10;
    box-shadow: 2px 2px 20px #CCCCCC;
    box-sizing: border-box;
  }

  .buy-btn-group {
    border-radius: 30px;
    width: 80%;
    border: 2px solid #3385ff;
    margin: 0 auto;
    display: flex;
    background-color: #3385ff;
  }

  .mobile_buy_button1 {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    color: #3385ff;
    background-color: white;
    transition: background-color 300ms ease;
  }

  .mobile_buy_button1:active {
    background-color: #EEEEEE;

  }

  .mobile_buy_button2 {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    //background-color: #3385ff;
    color: white;
  }
  .mobile_buy_button1, .mobile_buy_button2 {
    flex: 1;
    height: 40px;
    border-radius:40px;
    p {
      height: 100%;
      line-height: 40px;
      text-align: center;
      font-size: 15px;
      user-select: none;
      margin: 0;
    }

  }

  .mobile_buy_button_text {
    height: 40px;
    margin: 0;
    text-align: center;
    line-height: 40px;
  }

  .send_comments{
    width:94%;
    height:30px;
    position:fixed;
    bottom:5px;
    left:3%;
    background:#E9EAEB;
    border-radius:30px;
    Input{
    font-size:14px;
    height:30px;
    }
    Button{
    background:#13c2c2;
    height:30px;
    float:right;
    width:18%;
    border:0px;
    font-size:12px;
    color:white;
    }
}

}
img {
  width: 100%;
}