.details {
	position: absolute;
	background: white;
	width: 100%;
	
	
	.Slidingload{
		overflow: auto; //设置scroll overflow
		-webkit-overflow-scrolling: touch; // 优化移动端滑动体验

	}
	.typeBtn{
	z-index:50;
	height:26px;
	line-height:10px;
	background:#597ef7;
	margin-left:10px;
	color:white;
	position: fixed;
	top: 2px;
    font-size:12px;
	}
	.detail_head {
		width: 100%;
		position: fixed;
		top: 0px;
		text-align: right;
		background: #f5f5f5;
		padding: 5px 20px;
		z-index:40;
	}
	.detail_btn{
	width:"100%";
	border:"1px solid red";
	position:fixed;
    top:30px;
	}

	.detail_item_checkbox {
		width: 90%;
		margin-left: 8%;
		height: 80px;
		border-bottom: 1px solid #f5f5f5;
		padding: 15px 10px;
		font-size: 16px;
		color: black;
        position:relative;
		.detail_time {
			margin-top: 10px;
			color: #cccccc;
			font-size: 14px;
		}

		.head_url {
			width: 20%;
			float: left;
		}

		.detail_main {
			width: 100%;
		}
		.detail_checkbox{
		    position:absolute;
		    top:25px;
		    left:-20px;
		}
	}

	.detail_item {
		width: 90%;
		margin-left: 5%;
		height: 80px;
		border-bottom: 1px solid #f5f5f5;
		padding: 15px 10px;
		font-size: 16px;
		color: black;
        position:relative;
		.detail_time {
			margin-top: 10px;
			color: #cccccc;
			font-size: 14px;
		}

		.head_url {
			width: 20%;
			float: left;
		}

		.detail_main {
			width: 100%;
		}
	}

	.detail_bottom {
		height: 80px;
		width: 100%;
		position: fixed;
		bottom: 0;
		display: flex;
		justify-content: center;

		.adm-button {
			width: 33%;
		}
	}
}