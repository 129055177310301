.loadimg{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index:5000;
    background:url('https://micro-weixun.oss-cn-beijing.aliyuncs.com/static/load2.jpg');
    background-size: 100% 100%;
}
.loadimggif{
    position: absolute;
    width: 14%;
    top: 45%;
    padding-bottom: 15%;
    left: 43%;
    z-index: 500000;
}
.sunonelogo{
    position: relative;
    top: 1rem;
    width: 4.5rem;
    height: 3.9rem;
    text-align: center;
    margin: 0 auto;
    background:url(../photo/logo.png);
    background-size:cover;
}
.sunonetitle{
    position: absolute;
    width: 100%;
    top: 3.7rem;
    text-align: center;
    margin: 0 auto;
    font-size: 1.5rem;
    color: white;
    background: orange;
}
.sunonep{
    position: absolute;
    width: 84%;
    left: 8%;
    top: 6.8rem;
    text-align: center;
    font-weight: 600;
    margin: 0 auto;
    font-size: 1rem;
    color: white;
}
.sunonepp{
    position: absolute;
    width: 84%;
    left: 8%;
    top: 9rem;
    text-align: center;
    font-weight: 600;
    margin: 0 auto;
    font-size: 1rem;
    color: white;
}
.sunoneppp{
    position: absolute;
    width: 100%;
    top: 14rem;
    text-align: center;
    margin: 0 auto;
    font-size: 1.5rem;
    color: white;
}
.sunonepppp{
    position: absolute;
    width: 84%;
    left: 8%;
    top: 17rem;
    text-align: left;
    font-weight: 600;
    margin: 0 auto;
    font-size: 1rem;
    color: white;
}
.bacone{
    width: 100%;
    height:100%;
    background:url(../photo/peitao.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.baconebottom{
    position: fixed;
    z-index: 1000;
    width: 100%;
    bottom: 0%;
    height: 5rem;
    background:url(../photo/fourbottom.png);
    background-size: 100% 100%;
}
.onebac{
    position: absolute;
    width:100%;
    height:100%;
}
.onebactui{
    position: absolute;
    top: 74%;
    left: 10%;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 125%;
    font-weight: 600;
    z-index: 100;
    background:url(../photo/tuichu.png);
    background-size: 100% 100%;

}
.onebacs{
    position: relative;
    left: 5%;
    width: 90%;
    top: 4%;
    height: 72%;
}

.sunbactwo{
    width: 100%;
    height:100%;
    background:url(../photo/shipin.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.twoone{
    position: fixed;
    z-index: 1100;
    bottom: 0;
    left: 6%;
    width: 16%;
    height: 7%;
}
.twotwo{
    position: fixed;
    z-index: 1100;
    bottom: 0;
    left: 24%;
    width: 16%;
    height: 7%;
}
.twothree{
    position: fixed;
    z-index: 1100;
    bottom: 0;
    right: 24%;
    width: 16%;
    height: 7%;
}
.twofour{
    position: fixed;
    z-index: 1100;
    bottom: 0;
    right: 6%;
    width: 16%;
    height: 7%;
}
.twobottom{
    position: fixed;
    z-index: 1100;
    bottom: 4%;
    left: 42%;
    width: 16%;
    height: 7%;
}

.preloadVideo .open_btn{
    /*width:auto;
    height: 3rem;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 135%;
    left: 40%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    line-height: 40px;
    font-size: 24rem;
    color: black;
    border-radius: 50%;
    transition: all 400ms ease;
*/}
.sunbacthree{
    width: 100%;
    height:100%;
    background:url(../photo/peitao.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;

}
.sunthreetop{
    position: relative;
    top: 1.5rem;
    width: 9rem;
    height: 3.5rem;
    text-align: center;
    margin: 0 auto;
    background: url(../photo/peitaotitle.png);
    background-size: cover;
}
@media screen and (max-height: 668px) {
    .animfirstbtn {
        position: absolute;
        width: 50%;
        text-align: center;
        line-height: 2.1rem;
        left: 25%;
    
        top: 78%;
        height: 7%;

        background-size: 100% 100%;
        z-index: 10001;
    }
    .sunthreejuxing{
        position: absolute;
        top: 15%;
        left: 12%;
        width: 76%;
        font-size: 125%;
        font-weight: 600;
        height: 60%;
        background: url(../photo/tankuang.png);
        background-size: 100% 100%;
        padding: 3rem 2rem 2rem 2rem;
        padding-bottom: 10rem;
        text-indent: 16%;
        display: -webkit-box;
        -webkit-box-pack: center;
        -webkit-box-align: center;
        display: -moz-box;
        -moz-box-pack: center;
        -moz-box-align: center;
        display: -o-box;
        -o-box-orient: horizontal;
        -o-box-pack: center;
        -o-box-align: center;
        display: -ms-box;
        -ms-box-orient: horizontal;
        -ms-box-pack: center;
        -ms-box-align: center;
        display: box;
        box-orient: horizontal;
        box-pack: center;
        box-align: center;
    }
    .sunthreetui{
        position: absolute;
        top: 60%;
        left: 18%;
        width: 3rem;
        font-size: 125%;
        font-weight: 600;
        z-index: 100;
        height: 3rem;
        background: url(../photo/tuichu.png);
        background-size: 100% 100%;
    }
    .sunthreetop {
        position: relative;
        top: 1.5rem;
        width: 9rem;
        height: 3.5rem;
        text-align: center;
        margin: 0 auto;
        background: url(../photo/peitaotitle.png);
        background-size: cover;
    }
    .sunthreebtnone{

        position: absolute;
        left: 8%;
        top: 10rem;
        width: 7.8rem;
        height: 2.8rem;
        background: url(../photo/wenti.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    .sunthreebtnones{
        position: absolute;
        left: 8%;
        top: 9.8rem;
        width: 7.8rem;
        height: 3.8rem;
        background: url(../photo/wentis.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    .sunthreebtntwo{
        position: absolute;
        right: 8%;
        top: 10rem;
        width: 7.8rem;
        height: 2.8rem;
        background: url(../photo/jiankang.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    .sunthreebtntwos{
        position: absolute;
        right: 8%;
        top: 9.8rem;
        width: 7.8rem;
        height: 3.8rem;
        background: url(../photo/jiankangs.png);
        background-size: contain;
        background-repeat: no-repeat;
    }
    .sunthreebtnthree{
        position: absolute;
        left: 8%;
        top: 16rem;
        width: 7.8rem;
        height: 2.8rem;;
        background: url(../photo/zhaopin.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    .sunthreebtnthrees{
        position: absolute;
        left: 8%;
        top: 15.8rem;
        width: 7.8rem;
        height: 3.8rem;
        background: url(../photo/zhaopins.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    .sunthreebtnfour{
        position: absolute;
        right: 8%;
        top: 16rem;
        width: 7.8rem;
        height: 2.8rem;;
        background: url(../photo/peixun.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    .sunthreebtnfours{
        position: absolute;
        right: 8%;
        top: 15.8rem;
        width: 7.8rem;
        height: 3.8rem;;
        background: url(../photo/peixuns.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    .sunthreebtnfive{
        position: absolute;
        left: 8%;
        top: 22rem;
        width: 7.8rem;
        height: 2.8rem;
        background: url(../photo/jinsheng.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    .sunthreebtnfives{
        position: absolute;
        left: 1.5rem;
        top: 21.8rem;
        width: 7.8rem;
        height: 3.8rem;
        background: url(../photo/jinshengs.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    .sunthreebtnsix{
        position: absolute;
        right: 8%;
        top: 22rem;
        width: 7.8rem;
        height: 2.8rem;
        background: url(../photo/duihua.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    .sunthreebtnsixs{
        position: absolute;
        right: 8%;
        top: 21.8rem;
        width: 7.8rem;
        height: 3.8rem;
        background: url(../photo/duihuas.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

}
@media screen and (min-height: 668px){
    .animfirstbtn{
        position: absolute;
        width: 50%;
        text-align: center;
        line-height: 2.6rem;
        left: 25%;
        top: 76%;
        height: 7%;
        background-size: 100% 100%;
        z-index: 10001;
    }
    .sunthreebtnone{
        position: absolute;
        left: 8%;
        top: 11rem;
        width: 9rem;
        height: 2.8rem;
        background: url(../photo/wenti.png);
        background-size: contain;
        background-repeat: no-repeat;
    }
    .sunthreebtnones{
        position: absolute;
        left: 8%;
        top: 10.7rem;
        width: 9.8rem;
        height: 3.8rem;
        background: url(../photo/wentis.png);
        background-size: contain;
        background-repeat: no-repeat;
    }
    .sunthreebtntwo{
        position: absolute;
        right: 8%;
        top: 11rem;
        width: 9rem;
        height: 2.8rem;
        background: url(../photo/jiankang.png);
        background-size: contain;
        background-repeat: no-repeat;
    }
    .sunthreebtntwos{
        position: absolute;
        right: 8%;
        top: 10.7rem;
        width: 9.8rem;
        height: 3.8rem;
        background: url(../photo/jiankangs.png);
        background-size: contain;
        background-repeat: no-repeat;
    }
    .sunthreebtnthree{
        position: absolute;
        left: 8%;
        top: 18rem;
        width: 9rem;
        height: 2.8rem;;
        background: url(../photo/zhaopin.png);
        background-size: contain;
        background-repeat: no-repeat;
    }
    .sunthreebtnthrees{
        position: absolute;
        left: 8%;
        top: 17.7rem;
        width: 9.8rem;
        height: 3.8rem;;
        background: url(../photo/zhaopins.png);
        background-size: contain;
        background-repeat: no-repeat;
    }
    .sunthreebtnfour{
        position: absolute;
        right: 8%;
        top: 18rem;
        width: 9rem;
        height: 2.8rem;;
        background: url(../photo/peixun.png);
        background-size: contain;
        background-repeat: no-repeat;
    }
    .sunthreebtnfours{
        position: absolute;
        right: 8%;
        top: 17.7rem;
        width: 9.8rem;
        height: 3.8rem;
        background: url(../photo/peixuns.png);
        background-size: contain;
        background-repeat: no-repeat;
    }
    .sunthreebtnfive{
        position: absolute;
        left: 8%;
        top: 25rem;
        width: 9rem;
        height: 2.8rem;
        background: url(../photo/jinsheng.png);
        background-size: contain;
        background-repeat: no-repeat;
    }
    .sunthreebtnfives{
        position: absolute;
        left: 8%;
        top: 24.7rem;
        width: 9.8rem;
        height: 3.8rem;
        background: url(../photo/jinshengs.png);
        background-size: contain;
        background-repeat: no-repeat;
    }
    .sunthreebtnsix{
        position: absolute;
        right: 8%;
        top: 25rem;
        width: 9rem;
        height: 2.8rem;
        background: url(../photo/duihua.png);
        background-size: contain;
        background-repeat: no-repeat;
    }
    .sunthreebtnsixs{
        position: absolute;
        right: 8%;
        top: 24.7rem;
        width: 9.8rem;
        height: 3.8rem;
        background: url(../photo/duihuas.png);
        background-size: contain;
        background-repeat: no-repeat;
    }
    .sunthreejuxing{
        position: absolute;
        top: 15%;
        left: 12%;
        width: 76%;
        font-size: 125%;
        font-weight: 600;
        height: 54%;
        background: url(../photo/tankuang.png);
        background-size: 100% 100%;
        padding: 3rem 2rem 2rem 2rem;
        padding-bottom:10rem;
        text-indent: 16%;
        display: -webkit-box;
        -webkit-box-orient: horizontal;
        -webkit-box-pack: center;
        -webkit-box-align: center;

        display: -moz-box;
        -moz-box-orient: horizontal;
        -moz-box-pack: center;
        -moz-box-align: center;

        display: -o-box;
        -o-box-orient: horizontal;
        -o-box-pack: center;
        -o-box-align: center;

        display: -ms-box;
        -ms-box-orient: horizontal;
        -ms-box-pack: center;
        -ms-box-align: center;

        display: box;
        box-orient: horizontal;
        box-pack: center;
        box-align: center;
    }
    .sunthreetui{
        position: absolute;
        top: 57%;
        left: 18%;
        width: 3rem;
        font-size: 125%;
        font-weight: 600;
        z-index: 100;
        height: 3rem;
        background: url(../photo/tuichu.png);
        background-size: 100% 100%;
    }
}


.bacfour{
    width: 100%;
    height: 100%;
    background: url(../photo/bacfour.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim{
    width: 100%;
    height: 100%;
}
.anim-onebac{
    position: relative;
    width: 100%;
    height: 100%;

    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-onech{
    position: absolute;
    width: 66%;
    left: 16%;
    bottom: 1%;
}
.anim-twobac{
    position: relative;
    display: none;
    width: 100%;
    height: 100%;
    background: url(../photo/twobac.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-twoch{
    position: absolute;
    width: 90%;
    left: 5%;
    bottom: 0;
}
.anim-threebac{
    position: relative;
    display: none;
     width: 100%;
     height: 100%;
     background: url(../photo/threebac.png);
     background-repeat:no-repeat;
     background-size: 100% 100%;
 }
.anim-threech{

    position: absolute;
    width: 70%;
    left: 5%;
    bottom: 0;
}
.anim-fourbac{
    position: relative;
     display: none;
     width: 100%;
     height: 100%;
     background: url(../photo/fourbac.png);
     background-repeat:no-repeat;
     background-size: 100% 100%;
 }
.anim-fourch{
    position: absolute;
    width: 90%;
    left: 5%;
    bottom: 8%;
}
.anim-fivebac{
    position: relative;
    display: none;
    width: 100%;
    height: 100%;
    background: url(../photo/fivebac.jpg);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-fivech{
    position: absolute;
    width: 70%;
    left: 23%;
    bottom: 7%;
}
.anim-sixbac{
    position: relative;
    display: none;
    width: 100%;
    height: 100%;
    background: url(../photo/sixbac.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-sixch{

    position: absolute;
    width: 100%;
    left: 0%;
    bottom: 0;
}
.anim-sevenbac{
    position: relative;
    display: none;
    width: 100%;
    height: 100%;
    background: url(../photo/sevenbac.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-sevench{
    position: absolute;
    width: 60%;
    left: 20%;
    bottom: 0;
}
.anim-oneques{
    position: absolute;
    z-index: 1000;
    top: 6%;
    width: 80%;
    height: 25%;
    left: 10%;
    background: url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/quesone.png");
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
@keyframes chuxian {
    0%{
        opacity: 0;
    }
    90%{
        opacity: 0.1;
    }
    100%{
        opacity: 1;
    }
}
@keyframes dialogSlipToUp  {
    0%{
        transform: translate(0, -50%);
        width:0;
        left:50%;
        opacity: 0.3;
    }
    100%{
        transform: translate(0, 0);
        left:10%;
        width:80%;
        opacity: 1;
    }
}
.animfirst{
    position: absolute;
    width: 100%;
    font-size: 125%;
    font-weight: 600;
    height: 100%;
    background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/tankuangs.png');
    background-size: 100% 100%;
    
    z-index: 10000;
}

.animbac{
    position: absolute;
    top: 0%;
    width: 100%;
    height: 6.5%;
    z-index: 999;
    background: url('');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.anim-oneq{
    position: absolute;
    display: none;
    z-index: 1000;
    font-size: 220%;
    color: white;
    left: 18%;
    top: 18.5%;
}
.anim-onea{
     position: absolute;
    opacity: 0;
     z-index: 1000;
     font-size: 180%;
     left: 28%;
     top: 17%;

 }
.anim-oneaa{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    width:9.5%;
    text-align: center;
    color:white;
    height: auto;
    font-size: 180%;
    left: 15%;
    top: 17%;
    background: url(../photo/circle.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-oneb{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    font-size: 180%;
    left: 30%;
    top: 23%;
}
.anim-onebb{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    width:9.5%;
    color:white;
    text-align: center;
    height: auto;
    font-size: 180%;
    left: 15%;
    top: 23%;
    background: url(../photo/circle.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-oneok{
    position: absolute;
    z-index: 1000;
    width:10%;
    text-align: center;
    height: 7%;
    font-size: 180%;
    left: 15.5%;
    top: 24.5%;
    background: url(../photo/quesok.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-twoques{
    position: absolute;
    z-index: 1000;
    top: 6%;
    width: 80%;
    height: 28%;
    left: 10%;
    background: url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/questwo.png");
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-twoq{
    position: absolute;
    display: none;
    z-index: 1000;
    font-size: 190%;
    color: white;
    font-weight: 600;
    width: 70%;
    left: 17%;
    top: 11%;
}
.anim-twoaa{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    width:9.5%;
    color:white;
    text-align: center;
    height: auto;
    font-size: 180%;
    left: 15%;
    top: 19%;
    background: url(../photo/circle.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-twoa{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    font-size: 140%;
    width: 58%;
    left: 26%;
    top: 19%;
}
.anim-twobb{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    width:9.5%;
    color:white;
    text-align: center;
    height: auto;
    font-size: 180%;
    left: 15%;
    top: 26%;
    background: url(../photo/circle.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-twook{
    position: absolute;
    z-index: 1000;
    width:10%;
    color:white;
    text-align: center;
    height: 7%;
    font-size: 180%;
    left: 15.5%;
    top: 26.5%;
    background: url(../photo/quesok.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-twob{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    font-size: 140%;
    width: 66%;
    left: 26%;
    top: 26%;
}
.anim-threeques{
    position: absolute;
    z-index: 1000;
    top: 6%;
    width: 80%;
    height: 30%;
    left: 10%;
    background: url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/questhree.png");
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-threeq{
    position: absolute;
    display: none;
    z-index: 1000;
    font-size: 150%;
    font-weight: 600;
    color:white;
    width: 70%;
    left: 17%;
    top: 10%;
}
.anim-threeaa{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    width:9.5%;
    color:white;
    text-align: center;
    height: auto;
    font-size: 180%;
    left: 15%;
    top: 18%;
    background: url(../photo/circle.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-threea{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    font-size: 180%;
    width: 55%;
    left: 26%;
    top: 18%;
}
.anim-threeb{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    font-size: 170%;
    width: 66%;
    left: 26%;
    top: 24.5%;
}
.anim-threebb{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    width:9.5%;
    color:white;
    text-align: center;
    height: auto;
    font-size: 170%;
    left: 15%;
    top: 24.5%;
    background: url(../photo/circle.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-threec{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    font-size: 180%;
    width: 66%;
    left: 26%;
    top: 30%;
}
.anim-threecc{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    width:9.5%;
    color:white;
    text-align: center;
    height: auto;
    font-size: 180%;
    left: 15%;
    top: 30%;
    background: url(../photo/circle.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-threeok{
    position: absolute;
    z-index: 1000;
    width:10%;
    height: 7%;
    font-size: 180%;
    left: 15.5%;
    top: 30%;
    background: url(../photo/quesok.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-fourques{
    position: absolute;
    z-index: 1000;
    top: 6%;
    width: 80%;
    height: 30%;
    left: 10%;
    background: url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/quesfour.png");
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-fourq{
    position: absolute;
    display: none;
    z-index: 1000;
    font-size: 165%;
    font-weight: 600;
    width: 70%;
    color:white;
    left: 17%;
    top: 10%;
}
.anim-fouraa{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    width:9.5%;
    color:white;
    text-align: center;
    height: auto;
    font-size: 180%;
    left: 15%;
    top: 20%;
    background: url(../photo/circle.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-foura{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    font-size: 130%;
    width: 54%;
    left: 28%;
    top: 20%;
}
.anim-fourbb{
     position: absolute;
     opacity: 0;
     z-index: 1000;
     width:9.5%;
     color:white;
     text-align: center;
     height: auto;
     font-size: 180%;
     left: 15%;
     top: 28%;
     background: url(../photo/circle.png);
     background-repeat:no-repeat;
     background-size: 100% 100%;
 }
.anim-fourok{
    position: absolute;
    z-index: 1000;
    width:10%;
    height: 6%;
    font-size: 180%;
    left: 15.5%;
    top: 28.5%;
    background: url(../photo/quesok.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-fourb{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    font-size: 130%;
    width: 66%;
    left: 28%;
    top: 28%;
}
.anim-fiveques{
    position: absolute;
    z-index: 1000;
    top: 6%;
    width: 80%;
    height: 29%;
    left: 10%;
    background: url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/quesfive.png");
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-fiveq{
    position: absolute;
    display: none;
    z-index: 1000;
    font-size: 165%;
    font-weight: 600;
    width: 70%;
    color:white;
    left: 17%;
    top: 7%;
}
.anim-fiveaa{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    width:9.5%;
    color:white;
    text-align: center;
    height: auto;
    font-size: 180%;
    left: 15%;
    top: 18%;
    background: url(../photo/circle.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-fivea{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    font-size: 135%;
    width: 60%;
    left: 26%;
    top: 18%;
}
.anim-fivebb{
     position: absolute;
     opacity: 0;
     z-index: 1000;
     width:9.5%;
     color:white;
     text-align: center;
     height: auto;
     font-size: 180%;
     left: 15%;
     top: 24%;
     background: url(../photo/circle.png);
     background-repeat:no-repeat;
     background-size: 100% 100%;
 }
.anim-fiveok{
    position: absolute;
    z-index: 1000;
    width:10%;
    height: 6%;
    font-size: 180%;
    left: 15.5%;
    top: 24.5%;
    background: url(../photo/quesok.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-fiveb{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    font-size: 130%;
    width: 66%;
    left: 26%;
    top: 23%;
}
.anim-fivecc{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    width:9.5%;
    color:white;
    text-align: center;
    height: auto;
    font-size: 180%;
    left: 15%;
    top: 29%;
    background: url(../photo/circle.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-fivec{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    font-size: 130%;
    width: 66%;
    left: 26%;
    top: 30%;
}
.anim-sixques{
    position: absolute;
    z-index: 1000;
    top: 6%;
    width: 80%;
    height: 30%;
    left: 10%;
    background: url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/quessix.png");
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-sixq{
    position: absolute;
    display: none;
    z-index: 1000;
    font-size: 165%;
    color: white;
    font-weight: 600;
    width: 70%;
    left: 17%;
    top: 7%;
}
.anim-sixaa{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    width:9.5%;
    color:white;
    text-align: center;
    height: auto;
    font-size: 180%;
    left: 15%;
    top: 17.5%;
    background: url(../photo/circle.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-sixa{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    font-size: 140%;
    width: 60%;
    left: 26%;
    top: 18%;
}
.anim-sixbb{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    width:9.5%;
    color:white;
    text-align: center;
    height: auto;
    font-size: 180%;
    left: 15%;
    top: 23%;
    background: url(../photo/circle.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-sixb{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    font-size: 130%;
    width: 66%;
    left: 26%;
    top: 24%;
}
.anim-sixcc{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    width:9.5%;
    color:white;
    text-align: center;
    height: auto;
    font-size: 180%;
    left: 15%;
    top: 29%;
    background: url(../photo/circle.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-sixok{
    position: absolute;
    z-index: 1000;
    width:9.5%;
    height: 6%;
    font-size: 180%;
    left: 16%;
    top: 30%;
    background: url(../photo/quesok.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-sixc{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    font-size: 130%;
    width: 66%;
    left: 26%;
    top: 28%;
}
.anim-sevenques{
    position: absolute;
    z-index: 1000;
    top: 6%;
    width: 80%;
    height: 28%;
    left: 10%;
    background: url("https://weixun-demo.oss-cn-beijing.aliyuncs.com/sun/quesseven.png");
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-sevenq{
    position: absolute;
    display: none;
    color: white;
    z-index: 1000;
    font-size: 165%;
    color:white;
    font-weight: 600;
    width: 70%;
    left: 17%;
    top: 7%;
}
.anim-sevenaa{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    width:9.5%;
    color:white;
    text-align: center;
    height: auto;
    font-size: 180%;
    left: 15%;
    top: 19%;
    background: url(../photo/circle.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-sevena{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    font-size: 140%;
    width: 60%;
    left: 27%;
    top: 20%;
}
.anim-sevenbb{
    position: absolute;
    opacity: 0;
    z-index: 1000;
    width:9.5%;
    color:white;
    text-align: center;
    height: auto;
    font-size: 180%;
    left: 15%;
    top: 26%;
    background: url(../photo/circle.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-sevenb {
    position: absolute;
    opacity: 0;
    z-index: 1000;
    font-size: 130%;
    width: 66%;
    left: 27%;
    top: 27%;
}
.animtwobac{
    position: fixed;
    width: 700%;
    height: 100%;
    background: url(../photo/long.jpg);
    background-size: 100% 100%;
}
@keyframes shaky-slow {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    15% {
        transform: translate(-1px, 1.5px) rotate(5deg);
    }
    30% {
        transform: translate(1.3px, 0px) rotate(-5deg);
    }
    45% {
        transform: translate(1.4px, 1.4px) rotate(5deg);
    }
    60% {
        transform: translate(-1.3px, -1px) rotate(-5deg);
    }
    75% {
        transform: translate(1.4px, 0px) rotate(5deg);
    }
    90% {
        transform: translate(-1.3px, -1px) rotate(-5deg);
    }
    100% {
        transform: translate(1px, 1px) rotate(0deg);
    }

}
.anim-sucone{
    position: absolute;
    top: 2%;
    width: 80%;
    height: 37%;
    left: 10%;
    background: url(../photo/congratulate.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.anim-suc{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    background: url(../photo/success.png);
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
@media screen and (max-height: 668px){
    /*
    .anim-oneq {
        position: absolute;
        z-index: 1000;
        font-size: 190%;
        color: white;
        left: 18%;
        top: 18.5%;
    }

    .anim-onea{
        position: absolute;
        z-index: 1000;
        font-size: 170%;
        left: 36%;
        top: 28%;

    }
    .anim-oneb {
        position: absolute;
        z-index: 1000;
        font-size: 170%;
        left: 36%;
        top: 35%;
    }

    .anim-oneaa{
        position: absolute;
        z-index: 1000;
        width:9.5%;
        text-align: center;
        color:white;
        height: auto;
        font-size: 180%;
        left: 22%;
        top: 28%;
        background: url(../photo/circle.png);
        background-repeat:no-repeat;
        background-size: 100% 100%;
    }

    .anim-twoques {
        position: absolute;
        z-index: 1000;
        top: 11%;
        width: 80%;
        height: 34%;
        left: 10%;
        background: url(../photo/ques.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .anim-twoq {
        position: absolute;
        z-index: 1000;
        font-size: 160%;
        color: white;
        font-weight: 600;
        width: 70%;
        left: 17%;
        top: 11%;
    }
    .anim-twoa {
        position: absolute;
        z-index: 1000;
        font-size: 125%;
        width: 58%;
        left: 26%;
        top: 25%;
    }
    .anim-twob {
        position: absolute;
        z-index: 1000;
        font-size: 125%;
        width: 63%;
        left: 26%;
        top: 30%;
    }
    .anim-threeques {
        position: absolute;
        z-index: 1000;
        top: 8%;
        width: 80%;
        height: 39%;
        left: 10%;
        background: url(../photo/ques.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .anim-threeq {
        position: absolute;
        z-index: 1000;
        font-size: 140%;
        font-weight: 600;
        color: white;
        width: 70%;
        left: 17%;
        top: 10%;
    }
    .anim-threea {
        position: absolute;
        z-index: 1000;
        font-size: 130%;
        width: 55%;
        left: 26%;
        top: 24%;
    }
    .anim-threeb {
        position: absolute;
        z-index: 1000;
        font-size: 130%;
        width: 66%;
        left: 26%;
        top: 31%;
    }
    .anim-threec {
        position: absolute;
        z-index: 1000;
        font-size: 130%;
        width: 66%;
        left: 26%;
        top: 38%;
    }
    .anim-fourq {
        position: absolute;
        z-index: 1000;
        font-size: 150%;
        font-weight: 600;
        width: 70%;
        color: white;
        left: 17%;
        top: 10%;
    }
    .anim-fiveq {
        position: absolute;
        z-index: 1000;
        font-size: 150%;
        font-weight: 600;
        width: 70%;
        color: white;
        left: 17%;
        top: 7%;
    }
    .anim-fivea {
        position: absolute;
        z-index: 1000;
        font-size: 120%;
        width: 60%;
        left: 26%;
        top: 29%;
    }
    .anim-fiveaa {
        position: absolute;
        z-index: 1000;
        width: 9.5%;
        color: white;
        text-align: center;
        height: auto;
        font-size: 160%;
        left: 15%;
        top: 28%;
        background: url(../photo/circle.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .anim-fivebb {
        position: absolute;
        z-index: 1000;
        width: 9.5%;
        color: white;
        text-align: center;
        height: auto;
        font-size: 160%;
        left: 15%;
        top: 37%;
        background: url(../photo/circle.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .anim-fivecc {
        position: absolute;
        z-index: 1000;
        width: 9.5%;
        color: white;
        text-align: center;
        height: auto;
        font-size: 160%;
        left: 15%;
        top: 46%;
        background: url(../photo/circle.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .anim-fiveques {
        position: absolute;
        z-index: 1000;
        top: 5%;
        width: 80%;
        height: 54%;
        left: 10%;
        background: url(../photo/ques.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .anim-fiveb {
        position: absolute;
        z-index: 1000;
        font-size: 120%;
        line-height: 120%;
        width: 62%;
        left: 26%;
        top: 36.5%;
    }
    .anim-fivec {
        position: absolute;
        z-index: 1000;
        font-size: 130%;
        width: 62%;
        line-height: 120%;
        left: 26%;
        top: 46%;
    }
    .anim-sixq {
        position: absolute;
        z-index: 1000;
        font-size: 155%;
        color: white;
        font-weight: 600;
        width: 70%;
        left: 17%;
        top: 7%;
    }
    .anim-sixques {
        position: absolute;
        z-index: 1000;
        top: 5%;
        width: 80%;
        height: 37%;
        left: 10%;
        background: url(../photo/ques.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .anim-sixbb {
        position: absolute;
        z-index: 1000;
        width: 9.5%;
        color: white;
        text-align: center;
        height: auto;
        font-size: 180%;
        left: 15%;
        top: 27%;
        background: url(../photo/circle.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .anim-sixb {
        position: absolute;
        z-index: 1000;
        font-size: 130%;
        width: 66%;
        left: 26%;
        top: 28%;
    }
    .anim-sixc {
        position: absolute;
        z-index: 1000;
        font-size: 130%;
        width: 62%;
        line-height: 120%;
        left: 26%;
        top: 34%;
    }
    .anim-sevenques {
        position: absolute;
        z-index: 1000;
        top: 5%;
        width: 80%;
        height: 37%;
        left: 10%;
        background: url(../photo/ques.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .anim-sevenq {
        position: absolute;
        color: white;
        z-index: 1000;
        font-size: 150%;
        color: white;
        font-weight: 600;
        width: 70%;
        left: 17%;
        top: 7%;
    }
    .anim-sevenaa {
        position: absolute;
        z-index: 1000;
        width: 9.5%;
        color: white;
        text-align: center;
        height: auto;
        font-size: 180%;
        left: 15%;
        top: 21%;
        background: url(../photo/circle.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .anim-sevena {
        position: absolute;
        z-index: 1000;
        font-size: 140%;
        width: 60%;
        left: 27%;
        top: 22%;
    }
    .anim-sevenbb {
        position: absolute;
        z-index: 1000;
        width: 9.5%;
        color: white;
        text-align: center;
        height: auto;
        font-size: 180%;
        left: 15%;
        top: 30%;
        background: url(../photo/circle.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .anim-sevenb {
        position: absolute;
        z-index: 1000;
        font-size: 130%;
        width: 66%;
        left: 27%;
        top: 31%;
    }
    */
}
.anim-suconep{
    position: absolute;
    color: white;
    top: 28%;
    left: 22%;
    font-size: 165%;
}
.anim-suconep2{
    position: absolute;
    color: white;
    top: 28%;
    left: 62%;
    font-size: 165%;
}
.anim-suctwop{
    position: absolute;
    color: white;
    top: 37%;
    left: 22%;
    font-size: 165%;
}
.anim-suctwop2{
    position: absolute;
    color: white;
    top: 37%;
    left: 62%;
    font-size: 165%;
}
.anim-sucthreep{
    position: absolute;
    color: white;
    top: 45.5%;
    left: 22%;
    font-size: 165%;
}
.anim-sucthreep2{
    position: absolute;
    color: white;
    top: 45.5%;
    left: 62%;
    font-size: 165%;
}
.anim-sucfourp{
    position: absolute;
    color: #3c3a38;
    top: 53.7%;
    left: 22%;
    font-size: 165%;
}
.anim-sucfourp2{
    position: absolute;
    color: #3c3a38;
    top: 53.7%;
    left: 62%;
    font-size: 165%;
}
.anim-sucfivep{
    position: absolute;
    color:#3c3a38;
    top: 62%;
    left: 22%;
    font-size: 165%;
}
.anim-sucfivep2{
    position: absolute;
    color:#3c3a38;
    top: 62%;
    left: 62%;
    font-size: 165%;
}
.anim-sucsixp{
     position: absolute;
     color:#3c3a38;
     top: 70%;
     left: 22%;
     font-size: 165%;
 }
.anim-sucsixp2{
    position: absolute;
    color:#3c3a38;
    top: 70%;
    left: 62%;
    font-size: 165%;
}
.anim-sucsevenp{
    position: absolute;
    color:#3c3a38;
    top: 78%;
    left: 22%;
    font-size:165%;
}
.anim-sucsevenp2{
    position: absolute;
    color:#3c3a38;
    top: 78%;
    left: 62%;
    font-size:165%;
}
.anim-suceightp{
    position: absolute;
    color:#3c3a38;
    top: 86%;
    left: 22%;
    font-size: 165%;
}
.anim-suceightp2{
    position: absolute;
    color:#3c3a38;
    top: 86%;
    left: 62%;
    font-size: 165%;
}
.anim-sucninep{
    position: absolute;
    color:#3c3a38;
    top: 94%;
    left: 22%;
    font-size: 165%;
}
.anim-sucninep2{
    position: absolute;
    color:#3c3a38;
    top: 94%;
    left: 62%;
    font-size: 165%;
}