/*html{*/
/*    overflow: hidden;*/
/*}*/
.newmesbanner{
    position: relative;
    top: 6%;
    width: 41%;
    padding-bottom: 18%;
    text-align: center;
    margin: 0 auto;
    background: url(../photo/newmelogo.png);
    background-size: 100% 100%;
}
.newmebacone{
    width: 100%;
    height:100%;
    background:url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/newmebac.png');
    background-repeat:no-repeat;
    background-size: 100% 100%;
}
.newmebanner{
    position: relative;
    top: 6%;
    width: 75%;
    padding-bottom: 24%;
    text-align: center;
    margin: 0 auto;
    background: url(../photo/fourbanner.png);
    background-size: 100% 100%;
}
.newmeonebac {
    position: absolute;
    width: 100%;
    height: 100%;
}
.newmeonebacs {
    position: relative;
    left: 5%;
    width: 90%;
    top: 9%;
    height: 75%;
}
.newmeonebactui {
    position: absolute;
    top: 89%;
    left: 10%;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 125%;
    font-weight: 600;
    z-index: 100;
    background: url(../photo/tuichu.png);
    background-size: 100% 100%;
}
.newmeonebactuis {
    position: absolute;
    top: 77%;
    left: 26%;
    width: 51%;
    height: 7%;
    font-size: 125%;
    font-weight: 600;
    z-index: 100;
    background-size: 100% 100%;
}
