.courseList {
  background:#f5f5f5;
  .type-select {
    width: 100%;
    padding: 10px 15px;
    background-color: #f5f5f5;
    margin-bottom: 15px;
    .names {
      color: #000000;
      font-size: 16px;
      padding: 10px 0 0 10px;
    }
  }
  .toTop{
  position:fixed;
  width:50px;
  height:50px;
  bottom:100px;
  right:0px
  }
  .courses {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    list-style: none;
    background: white;
    padding: 10px;
    .course-item {
      padding: 10px;
      width: 50%;
      background-color: white;
      transition: background-color 400ms ease;
      .course-index {
        img {
          width: 100%;
          height: 100%;
        }
        margin-bottom: 10px;
      }
      .lessonName, .lessonPrice {
        height: 20px;
        line-height: 20px;
        color: #000000;
        font-size: 13px;
        margin-bottom: 0;
      }
      .lessonPrice {
        color: red;
      }
    }
    .course-item:active {
      background-color: whitesmoke;
    }
  }
}