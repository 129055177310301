@media screen and (max-height: 668px) {
    .bac{
        font-size:calc(100vw / 96);
        width: 100%;
        height:100%;
        background:url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/xinnewmebac.jpg');
        background-size:100% 100%;
        background-repeat:no-repeat;
    }
    .newmedeng{
        position: absolute;
        left: 15%;
        top: 36%;
        width: 12%;
        height: 9%;
    }
    .newmeshan{
        position: absolute;
        left: 10%;
        top: 47%;
        width: 12%;
        height: 9%;
    }
    .btnone{
        position: absolute;
        
        left: 7%;
        top: 33%;
        width: 26%;
        padding-bottom: 26%;
        height: 0;
        background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/photo/newmeqiye.png');
        background-size: 100% 100%;
    }
    .btntwo{
        position: absolute;
        opacity: 0;
        left: 37%;
        top: 35%;
        width: 26%;
        padding-bottom: 29%;
        height: 0;
        
        background-size: 100% 100%;

    }
    .btnthree{
        position: absolute;
        opacity: 0;
        left: 67%;
        top: 35%;
        width: 26%;
        padding-bottom: 29%;
        height: 0;
        
        background-size: 100% 100%;
    }

    .title{
        position: relative;
        display: none;
        top: 4rem;
        width: 19rem;
        height: 1.8rem;
        text-align: center;
        margin: 0 auto;
        
        background-size: cover;
    }
    .banner{
        position: relative;
        display: none;
        top: 2rem;
        width: 4.6rem;
        height: 3rem;
        text-align: center;
        margin: 0 auto;
        
        background-size:cover;
    }
    .newmebottoms{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 35%;
        text-align: center;
        background-size:100% 100%;
    }
    .bottomsimg{
        position: relative;
        opacity: 0;
        
        height: 257.4px;
        width: 336px;
        background-size: 100% 100%;
    }
}
@media screen and (min-height: 669px) {
    .bac{
        font-size:calc(100vw / 96);
        width: 100%;
        height:100%;
        background:url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/newmethree/xinnewmebac.jpg');
        background-size:100% 100%;
        background-repeat:no-repeat;
    }
    .newmedeng{
        position: absolute;
        left: 15%;
        top: 36%;
        width: 12%;
        padding-bottom: 6%;
     }
    .newmeshan{
        position: absolute;
        left: 47%;
        top: 37%;
        width: 12%;
        height: 9%;
    }
    .btnone{
        position: absolute;
        left: 7%;
        top: 37%;
        
        width: 26%;
        padding-bottom: 24%;
        height: 0;
        background: url('https://weixun-demo.oss-cn-beijing.aliyuncs.com/photo/newmeqiye.png');
        background-size: 100% 100%;
    }
    .btntwo{
        position: absolute;
        left: 37%;
        opacity: 0;
        top: 36%;
        width: 26%;
        padding-bottom: 29%;
        height: 0;
        
        background-size: 100% 100%;

    }
    .btnthree{
        position: absolute;
        left: 64%;
        opacity: 0;
        top: 36%;
        width: 26%;
        padding-bottom: 29%;
        height: 0;
       
        background-size: 100% 100%;
    }

    .title{
        position: relative;
        display: none;
        top: 4rem;
        width: 19rem;
        height: 1.8rem;
        text-align: center;
        margin: 0 auto;
        
        background-size: cover;
    }
    .banner{
        position: relative;
        display: none;
        top: 2rem;
        width: 4.6rem;
        height: 3rem;
        text-align: center;
        margin: 0 auto;
       
        background-size:cover;
    }
    .newmebottoms{
        position: absolute;
        bottom: -16%;
        left: -10%;
        width: 120%;
        height: 24rem;
        text-align: center;

    }
    .bottomsimg{
        position: relative;
        opacity: 0;
     
        height: 260.8px;
        width: 353px;
        background-size: 100% 100%;
    }
}
