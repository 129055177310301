.question-mask{
    width: 100%;
    height: 100%;
    background: rgba(200, 200, 200, 0.8);
    color: red;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    transition: all 500ms ease;
}
.question-mask>.question{
    width: 75%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    background: #FFFFFF;
    padding: 15px 10px;
}
.question>h3{
    text-align: center;
    font-size: 20px;
}
.question>.submit-single{
    width: 70%;
    margin-left: 15%;
}
.close{
    position: absolute;
    top: 15px;
    right: 10px;
    width: 20px;
    text-align: center;
    height: 20px;
    font-size: 18px;
    line-height: 20px;
    font-weight: bold;
    color: #666666;
}
.close:after{
    content: '';
    display: block;
    clear: both;
}
.question>.question_wrapper {
    width: 90%;
    margin: 0 auto 15px;
    padding: 7px;
    border-radius: 10px;
    border: 1px solid #EEEEEE;
}
.question>.question_title {
    color: #333333;
    width: 90%;
    display: block;
    margin: 0 auto 10px;
    text-indent: 10px;
    line-height: 20px;
    font-size: 17px;
}
.question>.question_wrapper>.choose_item {
    padding: 7px;
    line-height: 20px;
    background: #FEFEFE;
    font-size: 15px;
    transition: all 400ms ease;
    border-radius: 10px;
}
.question>.question_wrapper>.select {
    color: #666666;
}
.question>.question_wrapper>.select:active {
    background: #EEEEEE;
}
.question>.question_wrapper>.selected {
    background: #EEEEEE;
    color: dodgerblue;
}
.answer_result {
    line-height: 20px;
    height: 20px;
    width: 100%;
    text-align: center;
}



